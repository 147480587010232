import React, { useEffect } from "react";
import { SpecialFilter } from "../classes/AppEnum";
import { UserService } from "../Services/UserService";
import CategoriesDropDown from "./categoriesDropDown";
import OrderSettingsLink from "./orderSettingsLink";
import $ from 'jquery';
import { Events } from "../classes/AppEnum";
import eventBus from "../classes/EventBus";
import { useState } from "react";
import AccountLink from "./accountLink";
import logo from "../assets/logo.svg";
import { Link } from "react-router-dom";

const Filterbar = ({
	specialFilters,
	handleClickOnOrderSettingChange,
	onAccountLinkClick,
	onLogoutClick,
	onAccountLinksDisplay,
}) => {
	const [assignedCategories, setAssignedCategories] = useState([]);
	const getAssignedCategories = () => {
		setAssignedCategories(UserService.assignedCategories());
	};
	useEffect(() => {
		eventBus.on(Events.AssignedCategoriesUpdated, () => {
			getAssignedCategories();
		});

		getAssignedCategories();

		return function cleanup() {
			eventBus.remove(Events.AssignedCategoriesUpdated);
		};
	}, [specialFilters]);

	const xfilterbar = () => {
		$("header .filterbar .shadewrap").animate({ left: "-100%" }, function () {
			$("header .filterbar").hide();
			$("header .filterbar").removeAttr("style");
			$("header .filterbar .shadewrap").removeAttr("style");
		});
	};

	const classNameForSpecialFilter = (specialFilter) => {
		switch (specialFilter) {
			case SpecialFilter.FAVOURITES:
				return "mitem favs";
			case SpecialFilter.PROMOTIONS:
				return "mitem topromo";
			case SpecialFilter.RETAIL_CLUB_PROMOTIONS:
				return "mitem rclub";
			default:
				return "mitem go";
		}
	};

	return (
		<div className="filterbar">
			<div className="shadewrap">
				<div className="menuheader">
					<img className="menulogo" src={logo} />
					<div onClick={xfilterbar} className="x">
						✖
					</div>
					<div className="eor"></div>
				</div>
				<div className="actbar">
					<AccountLink
						onSignIn={() => {
							// handleSignInLocally();
							// handleSignIn();
						}}
						onAccountLinkClick={onAccountLinkClick}
						onLogoutClick={onLogoutClick}
						onAccountLinksDisplay={onAccountLinksDisplay}
					/>

					{/* <button className="actbtn" onClick={onAccountLinksDisplay}>
					Sign In
				</button> */}
				</div>
				<div className="col-lg-8 links">
					{assignedCategories.length > 0 && (
						<CategoriesDropDown
							specialFilters={[SpecialFilter.ALL]}
							categories={assignedCategories}
						/>
					)}
					{specialFilters.map((specialFilter) => {
						return (
							<Link
								key={specialFilter.NAME}
								className={classNameForSpecialFilter(specialFilter)}
								to={"/products/specialFilters/" + specialFilter.NAME}
							>
								{specialFilter.NAME}
							</Link>
						);
					})}
				</div>
				<OrderSettingsLink
					handleClickOnChange={handleClickOnOrderSettingChange}
				/>
			</div>
		</div>
	);
};

export default Filterbar;
