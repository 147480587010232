import React from "react";
import SearchSuggestions from "./SearchSuggestions";
import SearchedProducts from "./SearchedProducts";

const SearchResults = ({ products, onClose }) => {
	return (
		<div>
			{products.length > 0 && (
				<div className="search-suggestion">
					<div className="flex">
						<SearchSuggestions />
						<SearchedProducts products={products} onClose={onClose} />
					</div>
				</div>
			)}
		</div>
	);
};

export default SearchResults;
