import { AppConstants } from "../misc/Constants.js";
import { APIService } from "./APIService.js";

export const ProductService = {
	getProductsWithBarcodes: function (barcodes, callback) {
		let params = { start: 1 };
		if (barcodes != null) {
			params.barcodes = barcodes;
		}
		APIService.get(
			"get_products_by_barcodes.php",
			params,
			(jsonResponse, error) => {
				this.handleResponse(jsonResponse, error, callback);
			}
		);
	},
	getSubstituteProduct(barcode, callback) {
		let params = { start: 1 };
		if (barcode != null) {
			params.barcodes = barcode;
		}
		APIService.get(
			"get_substitute_products_by_barcode.php",
			params,
			(jsonResponse, error) => {
				this.handleResponse(jsonResponse, error, callback);
			}
		);
	},
	getProducts: function (
		SpecialFilter,
		searchText,
		categoryId,
		subCategoryId,
		sideFilter,
		sideFilterOption,
		start,
		limit,
		supplierCode,
		barcodes,
		selectedSupplierShopImageKey,
		callback
	) {
		let params = {};
		if (SpecialFilter != null) {
			params = SpecialFilter.PARAMS;
		}
		if (start != null) {
			params.start = start;
		} else {
			params.start = null;
		}

		if (selectedSupplierShopImageKey != null) {
			params.supplierShopImageKey = selectedSupplierShopImageKey;
		} else {
			params.supplierShopImageKey = null;
		}

		params.limit = limit;

		if (categoryId != null) {
			params.h1Id = categoryId;
		} else {
			params.h1Id = "";
		}
		if (subCategoryId != null) {
			params.h2Id = subCategoryId;
		} else {
			params.h2Id = "";
		}
		if (supplierCode != null) {
			params.supplierCode = supplierCode;
		} else {
			params.supplierCode = "";
		}
		if (searchText && searchText.length > 0) {
			params.prdName = searchText;
		} else {
			params.prdName = "";
		}
		if (sideFilter != null && sideFilterOption != null) {
			params[sideFilter.paramName] = sideFilterOption.id;
		} else {
		}
		if (barcodes != null) {
			params.barcodes = barcodes;
		} else {
			params.barcodes = "";
		}
		this._getProducts(params, callback);

		if (sideFilter != null && sideFilterOption != null) {
			params[sideFilter.paramName] = sideFilter.defaultParamValue;
		}
	},
	getProductsByProductCodes: function (
		supplierCode,
		supplierShopImageKey,
		productCodes,
		callback
	) {
		if (
			productCodes == null &&
			(supplierCode == null || supplierShopImageKey == null)
		) {
			callback([], null, null);
			return;
		}

		APIService.get(
			"get_products_by_pcode.php",
			{
				supplierCode,
				supplierShopImageKey,
				prdCodes: productCodes,
			},
			(jsonResponse, error) => {
				this.handleResponse(jsonResponse, error, callback);
			}
		);
	},
	getSearchedProducts: function (searchText, start, limit, callback) {
		if (
			searchText.toLowerCase().includes("crisp") ||
			searchText.toLowerCase().includes("crisps")
		) {
			this.getProductsByProductCodes(
				"24728,24713,24730,24711,24736,24725,24714,24731,24718,24722",
				(products, cartRequirements, error) => {
					callback(products, searchText, error);
				}
			);
		} else {
			this._getProducts(
				{ start, limit, prdName: searchText },
				(products, cartRequirements, error) => {
					callback(products, searchText, error);
				}
			);
		}
	},

	getCart: function (callback) {
		//filter = 1 means API will return cart Products Only
		this._getProducts({ start: 1, filter: 1 }, callback);
	},

	//Get Products by calling APIService and sending it the required params.
	_getProducts: function (params, callback) {
		APIService.get("get_products.php", params, (jsonResponse, error) => {
			this.handleResponse(jsonResponse, error, callback);
		});
	},

	categorisedProducts: function (products) {
		let categorisedProducts = {};
		products.map((product) => {
			let categoryName = product.category;
			if (categorisedProducts[categoryName]) {
				categorisedProducts[categoryName] =
					categorisedProducts[categoryName].concat(product);
			} else {
				categorisedProducts[categoryName] = [product];
			}
		});
		return categorisedProducts;
	},
	changeFavouriteStatus: function (productId, callback) {
		APIService.post(
			"add_favourite_product.php",
			{ productId },
			(jsonResponse, error) => {
				callback(error);
			}
		);
	},
	addToCart: function (productId, quantity, callback) {
		APIService.post(
			"update_cart.php",
			{
				productId,
				qty: quantity,
			},
			(jsonResponse, error) => {
				//if error is null it means success
				callback(error);
			}
		);
	},
	addProductsToCart: function (products, callback) {
		APIService.post(
			"update_cart_full.php",
			{
				products: products.map((product) => {
					return { pid: product.id, quantity: product.qty };
				}),
			},
			(jsonResponse, error) => {
				//if error is null it means success
				callback(error);
			}
		);
	},
	getAllProductCategories: function (callback) {
		APIService.get("categories.php", {}, (responseJson, error) => {});
	},
	isProductNonPromotionalForCart: function (product) {
		if (
			product.scheme == "NA" ||
			product.scheme == "SP" ||
			product.scheme == "RC" ||
			product.scheme == "SS" ||
			product.scheme == "LE"
		) {
			return false;
		}
		return true;
	},
	isProductPriceMarked: function (product) {
		if (product) {
			let regex1 = /[0-9]P/; //for example 60P, 59P etc.
			let regex2 = /[0-9] FOR [0-9]/;

			return (
				product.name.match(regex1) ||
				// product.name.match(regex2) ||
				product.name.includes(AppConstants.POUND_SIGN)
			);
		}
		return false;
	},
	isProductSingle: function (product) {
		if (product) {
			return product.packsize == 1;
		}
		return false;
	},
	//handle response from APIService and call the callback functions.
	handleResponse: function (jsonResponse, error, callback) {
		if (error != null) {
			callback(null, null, error);
		}
		let products = jsonResponse.data.products;
		if (products == false) {
			products = [];
		}
		callback(
			products,
			jsonResponse.data.userInfo == null ? null : jsonResponse.data.userInfo[0],
			null
		);
	},
};
