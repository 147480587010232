import React from "react";
import eventBus from "../classes/EventBus";
import { Events } from "../classes/AppEnum";

const SignInToBuy = () => {
	return (
		<input
			className="signin"
			type="button"
			value="Sign In"
			onClick={() => {
				eventBus.dispatch(Events.SigninBoxShouldBeShown, {});
			}}
		/>
	);
};

export default SignInToBuy;
