import { error } from "jquery";
import { APIService } from "./APIService";
export const SupplierService = {
	getSuppliers: function (supplier = "", callback) {
		APIService.get(
			"get_active_suppliers.php",
			{ supplier },
			(jsonResponse, error) => {
				if (error != null) {
					callback(null, error);
					return;
				}
				let suppliers = jsonResponse.data.suppliers;
				callback(suppliers, null);
			}
		);
	},
	logVisitToSupplierShop: function (supplierCode, callback) {
		APIService.post(
			"add_supplier_shop_clicked.php",
			{ supplierCode },
			(jsonResponse, error) => {
				callback(error);
			}
		);
	},
};
