import React from "react";
import { useState } from "react";

const BarcodeColumnSelector = ({
	numberOfColumns,
	onColumnSelect,
	onClickOnPrevious,
}) => {
	const [selectedColumnForBarcode, setSelectedColumnForBarcode] =
		useState(null);
	const [selectedColumnForQuantity, setSelectedColumnForQuantity] =
		useState(null);

	return (
		<div class="pad">
			<h3 class="pad-tb">Import products</h3>
			<h5>From the below columns select the box that contains the barcodes</h5>
			<div>
				You may also select a column for Quantity, This is optional and a blank
				entry will make the default quantity as 1.
			</div>
			<div class="pad-tb field">
				<div
					class="importcol"
					style={{
						padding: "10px",
						border: "1px solid #CCCCCC",
						float: "left",
					}}
				>
					<h4 class="pad-b">Barcode</h4>
					<select
						class="select"
						onChange={(e) => {
							setSelectedColumnForBarcode(e.target.value);
						}}
					>
						<option key="0" disabled selected hidden>
							Select One
						</option>
						{[...Array(numberOfColumns)].map((elementInArray, index) => (
							<option value={index + 1} key={index + 1}>
								Column {index + 1}
							</option>
						))}
					</select>
				</div>
				<div
					class="importcol"
					style={{
						padding: "10px",
						marginLeft: "10px",
						border: "1px solid #CCCCCC",
						float: "left",
					}}
				>
					<h4 class="pad-b">Quantity</h4>
					<select
						class="select"
						onChange={(e) => {
							setSelectedColumnForQuantity(e.target.value);
						}}
					>
						<option key="0" disabled selected hidden>
							Select One
						</option>
						{[...Array(numberOfColumns)].map((elementInArray, index) => (
							<option value={index + 1} key={index + 1}>
								Column {index + 1}
							</option>
						))}
					</select>
				</div>

				<div class="eor"></div>
			</div>
			<div class="pad-t">
				<input
					type="button"
					value="Previous"
					class="btn btn120 btn-white"
					onClick={onClickOnPrevious}
				/>
				<input
					type="button"
					value="Next"
					class="mar20-l btn btn120"
					onClick={(e) => {
						if (
							selectedColumnForBarcode != null &&
							(selectedColumnForQuantity == null ||
								selectedColumnForBarcode != selectedColumnForQuantity)
						) {
							onColumnSelect(
								selectedColumnForBarcode,
								selectedColumnForQuantity
							);
						}
					}}
				/>
			</div>
		</div>
	);
};

export default BarcodeColumnSelector;
