import React from "react";

import nextArrowImage from "../assets/nxt-slide.png";

const NextArrow = ({ onClickHandler, marginTop }) => {
	return (
		<img
			className="next"
			style={{ marginTop: marginTop }}
			onClick={onClickHandler}
			src={nextArrowImage}
		/>
	);
};

export default NextArrow;
