import { AppConstants } from "../misc/Constants";

export const Utils = {
	isNumericOrEmpty: function (value) {
		if (value == null || value == undefined) {
			return false;
		}

		const regex = /^[0-9\b]+$/;
		if (value.length == 0 || regex.test(value)) {
			return true;
		}
		return false;
	},
	isDecimalOrEmpty: function (value) {
		if (value == null || value == undefined) {
			return false;
		}

		const regex = /^(\d*\.{0,1}\d{0,2}$)/;

		if (value.length == 0 || regex.test(value)) {
			return true;
		}
		return false;
	},

	directToApp: function () {
		this.openURLInNewTab(AppConstants.IOS_APP_URL);
	},
	directToStore: function () {
		this.openURLInNewTab(AppConstants.DIRECT_TO_STORE_URL);
	},
	openURLInNewTab: function (url) {
		if (url != null && url != undefined) {
			window.open(url);
		}
	},
	calculatePOR: function (caseCost, vatPercentage, unitPerCase, rrp) {
		const totalCost =
			parseFloat(caseCost) + parseFloat((caseCost * vatPercentage) / 100);
		const por = ((rrp * unitPerCase - totalCost) * 100) / (rrp * unitPerCase);
		const cashMargin = (rrp * unitPerCase - totalCost) / unitPerCase;

		return { por, cashMargin };
	},
};
