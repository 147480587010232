import React from "react";

const CartInstruction = ({ initialText, boldText, finalText }) => {
	return (
		<div className="pad-b txt-md-c">
			<p>{initialText} </p>
			<strong>{boldText}</strong> {finalText}
			<br />
			required to checkout
		</div>
	);
};

export default CartInstruction;
