import { AppConstants } from "../misc/Constants";

const ProductImage = ({
	supplierCode,
	productCode,
	largeImage,
	missingImage,
	height,
	width,
}) => {
	return (
		<img
			alt=""
			className="photo"
			height={height}
			width={width}
			src={
				largeImage !== ""
					? supplierCode === "W020"
						? AppConstants.IMAGE_BASE_URL +
						  "images/" +
						  productCode +
						  "_EnhancedProductShot5_500.jpeg"
						: AppConstants.IMAGE_BASE_URL + largeImage
					: process.env.REACT_APP_DEFAULT_IMAGE_URL
			}
			onError={(e) => {
				e.target.src = process.env.REACT_APP_DEFAULT_IMAGE_URL;
			}}
		/>
	);
};

export default ProductImage;
