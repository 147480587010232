import React from "react";

const JoinUs = ({ onClickOnLogin }) => {
	return (
		<section id="register-link-bar">
			<article className="col-md-6">
				Over 12,000 products ready for you to order.
			</article>
			<article className="col-md-6 links">
				Join us to get started.
				<a className="btn register" href="#">
					Register
				</a>
				<a className="btn signin" href="#" onClick={onClickOnLogin}>
					Login
				</a>
			</article>
			<article className="eor"></article>
		</section>
	);
};

export default JoinUs;
