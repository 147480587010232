import React from "react";
import { useRef, useState } from "react";
import { AppConstants } from "../misc/Constants";
import { Utils } from "../Services/Utils";

// (packsize x unit-size x abvPercentage)/10 = number-of-units
const MUPCalculator = () => {
	//This is to trigger re-render
	const [abvPercentage, setAbvPercentage] = useState(0);
	const [unitSizeInmls, setUnitSizeInmls] = useState(0);
	const [packsize, setPacksize] = useState(0);

	//Refs to input text fields
	const abvPercentageRef = useRef(0);
	const unitSizeInmlsRef = useRef(0);
	const packsizeRef = useRef(0);

	// (packsize x unit-size x abvPercentage)/10 = number-of-units
	const numberOfUnits = () => {
		return (
			(unitSizeInmlsRef.current.value *
				packsizeRef.current.value *
				(abvPercentageRef.current.value / 100)) /
			10.0
		).toFixed(2);
	};
	const MUP = () => {
		return (numberOfUnits() / 2).toFixed(2);
	};
	const handleChangeInABV = (newValue) => {
		if (Utils.isDecimalOrEmpty(newValue)) {
			setAbvPercentage(newValue);
		} else {
			abvPercentageRef.current.value = abvPercentage;
		}
	};
	const handleChangeInPacksize = (newValue) => {
		if (Utils.isNumericOrEmpty(newValue)) {
			setPacksize(newValue);
		} else {
			packsizeRef.current.value = packsize;
		}
	};

	const handleChangeInUnitSize = (newValue) => {
		if (Utils.isNumericOrEmpty(newValue)) {
			setUnitSizeInmls(newValue);
		} else {
			unitSizeInmlsRef.current.value = unitSizeInmls;
		}
	};

	return (
		<div className="calc mup-calc">
			<div className="inputs col-md-12">
				<div className="col-sm-4 col-md-6 col-lg-4">
					<div className="infield">
						<div className="lab">ABV %</div>
						<div>
							<input
								className="val"
								ref={abvPercentageRef}
								defaultValue=""
								value={abvPercentageRef.current.value}
								onChange={(e) => {
									handleChangeInABV(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-sm-4 col-md-6 col-lg-4">
					<div className="infield">
						<div className="lab">Unit size in mls</div>
						<div>
							<input
								className="val"
								defaultValue=""
								value={unitSizeInmlsRef.current.value}
								ref={unitSizeInmlsRef}
								onChange={(e) => {
									handleChangeInUnitSize(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-sm-4 push-md-3 col-md-6 push-lg-0 col-lg-4">
					<div className="infield">
						<div className="lab">Pack Size</div>
						<div>
							<input
								className="val"
								defaultValue=""
								value={packsizeRef.current.value}
								ref={packsizeRef}
								onChange={(e) => {
									handleChangeInPacksize(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-12 pad20-tb txt-md-c">
				<input className="btn" type="submit" value="CALCULATE" />
			</div>
			<div
				style={{ borderTop: "1px solid #ffffff" }}
				className="col-md-12 pad20-t"
			>
				<div className="col-sm-4 push-sm-2 push-md-1 col-md-10 col-lg-5">
					<div className="outfield">
						<div className="lab">Units</div>
						<div className="val">{numberOfUnits()}</div>
					</div>
				</div>
				<div className="col-sm-4 push-sm-2 push-md-1 col-md-10 col-lg-5">
					<div className="outfield">
						<div className="lab">MUP</div>
						<div className="val">{AppConstants.POUND_SIGN + MUP()}</div>
					</div>
				</div>
				<div className="eor"></div>
			</div>

			<div className="bottom-line col-md-12">
				1000 mls = 1 Litre. MUP is Calculated based on 50p per unit.
			</div>
		</div>
	);
};

export default MUPCalculator;
