import React, { useState } from "react";
import Categories from "./categories";
import SubCategories from "./subCategories";
import { Navigate } from "react-router-dom";

const CategoriesDropDown = ({ specialFilters, categories }) => {
	const [categoryUnderFocus, setCategoryUnderFocus] = useState(null);
	const [shouldShowCategories, setShouldShowCategories] = useState(false);
	return (
		<span
			className="mitem dropdown-tog"
			href="#"
			onMouseEnter={() => {
				setShouldShowCategories(true);
			}}
			onMouseLeave={() => {
				setShouldShowCategories(false);
			}}
		>
			Shop by Category
			<div id="shop-by-category" className="dropdown">
				<div className="flex">
					{shouldShowCategories && (
						<Categories
							specialFilters={specialFilters}
							assignedCategories={categories}
							onFocus={(category) => {
								setCategoryUnderFocus(category);
							}}
							onFocusEnd={(category) => {}}
							onFocusOnSpecialFilter={(specialFilter) => {
								setCategoryUnderFocus(null);
							}}
						/>
					)}
					{shouldShowCategories && categoryUnderFocus != null && (
						<SubCategories
							category={categoryUnderFocus}
							subcategories={categoryUnderFocus.H2}
						/>
					)}
				</div>
			</div>
		</span>
	);
};

export default CategoriesDropDown;
