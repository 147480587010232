import React from "react";
import { Utils } from "../Services/Utils";

const Booklet = ({ imageURL, fileURL, className }) => {
	return (
		<div className={className}>
			<img
				className="booklet"
				src={imageURL}
				onClick={() => {
					Utils.openURLInNewTab(fileURL);
				}}
			/>
		</div>
	);
};
export default Booklet;
