import React from "react";
import BrochureLink from "./Brochure-Link";
import AppLink from "./App-Link";

const BrochureAppLink = ({ onClickOnBrochure }) => {
	return (
		<div className="container">
			<section id="brochure-app" className="row">
				<BrochureLink onClick={onClickOnBrochure} />
				<AppLink />
			</section>
		</div>
	);
};

export default BrochureAppLink;
