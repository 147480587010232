import React from "react";

const OrderType = ({
	heading,
	text,
	image,
	shoulHaveRightBorder,
	onClick,
	onClickOnChange,
}) => {
	return (
		<div className="col-md-6">
			<div className={shoulHaveRightBorder ? "col sep " : "col"}>
				<a id="select-click-collect" className={"txt-md-c"} onClick={onClick}>
					<div className="pad20-t">
						<img src={image} />
					</div>
					<h3 className="pad-t">{heading}</h3>
					<p>{text}</p>
				</a>
				{shoulHaveRightBorder && (
					<a className="prodtype-cancel" onClick={onClickOnChange}>
						&#10094; Change
					</a>
				)}
			</div>
		</div>
	);
};

export default OrderType;
