import React from "react";

import favOff from "../assets/fav-off.svg";
import favOn from "../assets/fav-on.svg";

import { AppConstants } from "../misc/Constants";
import SignInToBuy from "./signinToBuy";
import { UserService } from "../Services/UserService";
import AddToCart from "./addToCart";
import ProductSticker from "./ProductSticker";
import offerSticker from "../assets/sticker-offer.svg";
import rclubSticker from "../assets/sticker-rclub.svg";
import pmpSticker from "../assets/sticker-pmp.svg";
import singleSticker from "../assets/single.svg";
import { ProductService } from "../Services/ProductService";
import SingleSticker from "./SingleSticker";
import ProductImage from "./product-image";

const SingleProductForListing = ({
	product,
	handleClickonAdd,
	handleClickOnFavourite,
	shouldShowSignInToBuy,
}) => {
	return (
		<div
			className={UserService.isSignedIn() ? "prod postlogin" : "prod prelogin"}
		>
			<div className="photo">
				<ProductSticker
					product={product}
					offerImage={offerSticker}
					rclubImage={rclubSticker}
				/>
				{UserService.isSignedIn() && (
					<img
						className="favourite"
						src={product.isFavourite == "1" ? favOn : favOff}
						onClick={handleClickOnFavourite}
					/>
				)}
				<a>
					<ProductImage
						supplierCode={product.supplier}
						productCode={product.code}
						largeImage={product.largeImage}
						missingImage={product.missingImage}
					/>
				</a>
				{ProductService.isProductPriceMarked(product) && (
					<img className="pmp" src={pmpSticker} />
				)}
			</div>
			<div className="detail">
				<div className="desc">{product.name}</div>
				<div className="sku">SKU: {product.code}</div>
				<div className="case">
					{product.packsize == 1 ? (
						<img src={singleSticker} />
					) : (
						"Case of " + product.packsize
					)}{" "}
					{" x " + product.packsize_desc}
				</div>
				{UserService.isSignedIn() && (
					<div className="price">
						{AppConstants.POUND_SIGN + product.price}
						<span className="striked">
							{product.stdPrice > product.price &&
								AppConstants.POUND_SIGN + product.stdPrice}
						</span>
					</div>
				)}
				<div className="porrp">
					<div className="rrp">
						RRP: {AppConstants.POUND_SIGN + product.rrp}
					</div>
					{UserService.isSignedIn() && (
						<div className="por">POR: {product.por}%</div>
					)}
					<div className="eor"></div>
				</div>
				<>
					{shouldShowSignInToBuy &&
						(UserService.isSignedIn() || <SignInToBuy />)}
					{UserService.isSignedIn() ? (
						<AddToCart
							quantity={product.qty}
							listedInCart={false}
							onAddClicked={handleClickonAdd}
						/>
					) : (
						<div className="form">
							<SignInToBuy />
						</div>
					)}
				</>
			</div>
		</div>
	);

	// return (
	// 	<div
	// 		onMouseOver={onMouseOver}
	// 		onMouseLeave={onMouseLeave}
	// 		className="prod item sep"
	// 	>
	// 		<a className="photo">
	// 			<img
	// 				src={
	// 					product.largeImage != ""
	// 						? AppConstants.IMAGE_BASE_URL + product.largeImage
	// 						: AppConstants.DEFAULT_IMAGE_URL
	// 				}
	// 			/>
	// 		</a>
	// 		<ProductSticker
	// 			product={product}
	// 			offerImage={offerSticker}
	// 			rclubImage={rclubSticker}
	// 		/>
	// 		{/* Display favourite icon if user is signed in. Hide it otherwise */}
	// 		{UserService.isSignedIn() && (
	// 			<div className="favourite">
	// 				{
	// 					<img
	// 						src={product.isFavourite == "1" ? favOn : favOff}
	// 						onClick={handleClickOnFavourite}
	// 					/>
	// 				}
	// 			</div>
	// 		)}
	// 		{/* if product is priced marked then show pmp sticker */}
	// 		{ProductService.isProductPriceMarked(product) && (
	// 			<div
	// 				className={
	// 					/* if user is signed in then we are having favourite icon and pmp sticker position depends on favourite icon.*/
	// 					UserService.isSignedIn()
	// 						? "pmpWithFavourite"
	// 						: "pmpWithoutFavourite"
	// 				}
	// 			>
	// 				<img src={pmpSticker} />
	// 			</div>
	// 		)}
	// 		<div className="eor"></div>
	// 		<div className="price-row">
	// 			{/* show price to signed in user only. */}
	// 			<div className="price">
	// 				{UserService.isSignedIn()
	// 					? AppConstants.POUND_SIGN + product.price
	// 					: "Login"}
	// 			</div>
	// 			<div className="por">
	// 				<div>Sell @ {AppConstants.POUND_SIGN + product.rrp}</div>
	// 				<div>POR {product.por}%</div>
	// 			</div>
	// 		</div>
	// 		<a className="desc">{product.name}</a>
	// 		<div className="pack-size">
	// 			{ProductService.isProductSingle(product) ? (
	// 				<>
	// 					<SingleSticker />x {product.packsize_desc}
	// 				</>
	// 			) : (
	// 				"Case of " + product.packsize + " x " + product.packsize_desc
	// 			)}
	// 		</div>
	// 		<div className="pmsave">
	// 			{saveAmountPerCase > 0 && (
	// 				<div className="save">
	// 					Save
	// 					{" " +
	// 						AppConstants.POUND_SIGN +
	// 						(product.stdPrice - product.price).toFixed(2)}
	// 				</div>
	// 			)}
	// 			<div className="eor"></div>
	// 		</div>
	// 		<div style={{ top: -60 }} className="btn-popup addcart-form">
	// 			{shouldShowSignInToBuy && (UserService.isSignedIn() || <SignInToBuy />)}
	// 			{UserService.isSignedIn() &&
	// 				(shouldShowSignInToBuy || product.qty > 0) && (
	// 					<AddToCart
	// 						quantity={product.qty}
	// 						listedInCart={false}
	// 						onAddClicked={handleClickonAdd}
	// 					/>
	// 				)}
	// 		</div>
	// 	</div>
	// );
};;

export default SingleProductForListing;
