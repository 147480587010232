import React, { useEffect } from "react";
import Checkbox from "./checkbox";
import CollapsibleContainer from "./collapsibleContainer";

const FilterBox = ({
	title,
	checkedOption,
	filterOptions,
	onSelect,
	onDeselect,
}) => {
	useEffect(() => {}, [checkedOption]);
	return (
		<div id="filterbox" className="filter">
			<h4>Filter</h4>
			<div className="filterGroup">
			{filterOptions.map((option) => {
					return (
						<Checkbox
							
							title={option.title}
							isChecked={checkedOption != null && option.id == checkedOption.id}
							key={option.id}
							onCheck={() => {
								onSelect(option);
							}}
							onUnCheck={() => {
								onDeselect(option);
							}}
						/>
					);
				})}
			</div>
		</div>
	);
};

export default FilterBox;