import axios from "axios";
import { AppConstants } from "../misc/Constants";
import { UserService } from "./UserService";
export const APIService = {
	get: function (apiEndPoint, params, callback) {
		if (UserService.isSignedIn()) {
			params.token = UserService.userToken();
		}
		axios
			.get(process.env.REACT_APP_API_URL + apiEndPoint, { params })
			.then((jsonResponse) => {
				this.handleJsonResponse(jsonResponse, null, callback);
			});
	},
	post: function (apiEndPoint, params, callback) {
		if (UserService.isSignedIn()) {
			params.token = UserService.userToken();
		}
		axios
			.post(process.env.REACT_APP_API_URL + apiEndPoint, params)
			.then((jsonResponse) => {
				this.handleJsonResponse(jsonResponse, null, callback);
			})
			.catch((error) => {
				this.handleJsonResponse(null, error, callback);
			});
	},
	handleJsonResponse: function (jsonResponse, error, callback) {
		if (error != null) {
			callback(null, error);
		} else if (
			jsonResponse.data.status != 200 &&
			jsonResponse.data.status != 1 &&
			jsonResponse.data.status != null
		) {
			callback(null, jsonResponse.data.msg);
		} else {
			callback(jsonResponse, null);
		}
	},
};
