import React, { useEffect, useState } from "react";

import phoneIcon from "../assets/depot-phone.png";
import timeIcon from "../assets/depot-time.png";
import locationIcon from "../assets/depot-location.png";
import { useLocation, useParams } from "react-router-dom";
import { stores } from "../misc/constantData";

const StoreInfo = () => {
	const { storeName } = useParams();
	let store = stores.filter((store) => store.name == storeName)[0];
	return (
		<>
			{store && (
				<section id="our-depot" className="pad20-t container">
					<article
						className="banner"
						style={{ backgroundImage: `url('${store.banner}')` }}
					>
						<div className="txt">
							<div className="heading">{store.name}</div>
							<div className="content">{store.contact_info.address}</div>
						</div>
					</article>
					<article className="info pad40-tb">
						<div className="col-md-4">
							<div className="pad40">
								<h2 className="pad-b light">{store.name}</h2>
								<div className="pad20-tb">
									<img className="float-l" src={locationIcon} />
									<div className="content">{store.contact_info.address}</div>
									<div className="eor"></div>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-sm-6">
							<div className="pad40">
								<h2 className="pad-b light">CONTACT INFO</h2>
								<div className="pad20-tb">
									<img className="float-l" src={phoneIcon} />
									<div className="content">
										Phone:{" "}
										<strong className="value">
											{store.contact_info.phone}
										</strong>
									</div>
									<div className="eor"></div>
								</div>
							</div>
						</div>
						<div className="col-md-4 col-sm-6">
							<div className="pad40">
								<h2 className="pad-b light">OPENNING TIMES</h2>
								<div className="pad20-tb">
									<img className="float-l" src={timeIcon} />
									<div className="content">
										<div className="pad-b">
											<div className="col-md-4 txt-md-l">Mon-Fri</div>
											<div className="col-md-8 txt-md-l">
												<strong className="value">
													{store.timings.mon_fri}
												</strong>
											</div>
											<div className="eor"></div>
										</div>
										<div className="pad-b">
											<div className="col-md-4 txt-md-l">Saturday</div>
											<div className="col-md-8 txt-md-l">
												<strong className="value">
													{store.timings.saturday}
												</strong>
											</div>
											<div className="eor"></div>
										</div>
										<div>
											<div className="col-md-4 txt-md-l">Sunday</div>
											<div className="col-md-8 txt-md-l">
												<strong className="value">
													{store.timings.sunday}
												</strong>
											</div>
											<div className="eor"></div>
										</div>
									</div>
									<div className="eor"></div>
								</div>
							</div>
						</div>
						<div className="eor"></div>
					</article>
				</section>
			)}
		</>
	);
};

export default StoreInfo;
