import React from "react";

import checkMark from "../assets/checkmark.svg";

const OrderCompleted = ({ amount, msg, onContinueToHomeClick }) => {
	return (
		<div id="add-card" className="txt-md-c box960 ocomplete">
			<div>
				<img className="icon" src={checkMark} />
			</div>
			<h1 className="pad20-tb">Order Complete</h1>
			<div className="amount">£{amount}</div>
			<div className="pad20-tb boxinfo">{msg}</div>

			<input
				className="btn btn320"
				type="button"
				value="Continue To Home"
				onClick={onContinueToHomeClick}
			/>
		</div>
	);
};

export default OrderCompleted;
