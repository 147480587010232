import React, { useRef, useState } from "react";
import { VATOptions } from "../classes/AppEnum";
import { Utils } from "../Services/Utils";

const PORCalculator = () => {
	const caseCostRef = useRef(0);
	const unitPerCaseRef = useRef(0);
	const vatPercentageRef = useRef(0);
	const rrpRef = useRef(0);

	const [caseCost, _setCaseCost] = useState(0);
	const [unitPerCase, _setUnitPerCase] = useState(0);
	const [vatPercentage, _setVatPercentage] = useState(0);
	const [rrp, _setRRP] = useState(0);

	const handleChangeInCaseCost = (newValue) => {
		if (Utils.isDecimalOrEmpty(newValue)) {
			_setCaseCost(newValue);
		} else {
			caseCostRef.current.value = caseCost;
		}
	};

	const handleChangeInUnitPerCase = (newValue) => {
		if (Utils.isNumericOrEmpty(newValue)) {
			_setUnitPerCase(newValue);
		} else {
			unitPerCaseRef.current.value = unitPerCaseRef;
		}
	};

	const handleChangeInVatPercentage = (newValue) => {
		if (Utils.isNumericOrEmpty(newValue)) {
			_setVatPercentage(newValue);
		} else {
			vatPercentageRef.current.value = vatPercentage;
		}
	};

	const handleChangeInRRP = (newValue) => {
		if (Utils.isDecimalOrEmpty(newValue)) {
			_setRRP(newValue);
		} else {
			rrpRef.current.value = rrp;
		}
	};

	const calculatePORAndCashMargin = () => {
		return Utils.calculatePOR(
			caseCostRef.current.value,
			vatPercentageRef.current.value,
			unitPerCaseRef.current.value,
			rrpRef.current.value
		);
	};
	return (
		<div className="calc por-calc">
			<div className="inputs col-md-12">
				<div className="col-sm-3 col-md-6 col-lg-3">
					<div className="infield">
						<div className="lab">Case Cost (£)</div>
						<div>
							<input
								className="val"
								ref={caseCostRef}
								value={caseCostRef.current.value}
								onChange={(e) => {
									handleChangeInCaseCost(e.target.value);
								}}
								defaultValue="0"
							/>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-6 col-lg-3">
					<div className="infield">
						<div className="lab">VAT</div>
						<div>
							<select
								ref={vatPercentageRef}
								className="select"
								onChange={(e) => {
									handleChangeInVatPercentage(e.target.value);
								}}
							>
								{VATOptions.getAll().map((vatOption) => {
									return (
										<option key={vatOption.title} value={vatOption.percentage}>
											{vatOption.title}
										</option>
									);
								})}
							</select>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-6 col-lg-3">
					<div className="infield">
						<div className="lab">Units per Case</div>
						<div>
							<input
								className="val"
								defaultValue="0"
								ref={unitPerCaseRef}
								value={unitPerCaseRef.current.value}
								onChange={(e) => {
									handleChangeInUnitPerCase(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-sm-3 col-md-6 col-lg-3">
					<div className="infield">
						<div className="lab">Sell at (£)</div>
						<div>
							<input
								className="val"
								defaultValue="0"
								ref={rrpRef}
								value={rrpRef.current.value}
								onChange={(e) => {
									handleChangeInRRP(e.target.value);
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="col-md-12 pad20-tb txt-md-c">
				<input className="btn" type="submit" value="CALCULATE" readOnly />
			</div>
			<div
				style={{ borderTop: "1px solid #ffffff" }}
				className="col-md-12 pad20-t"
			>
				<div className="col-sm-6 push-sm-1 col-md-10 col-lg-6">
					<div className="outfield">
						<div className="lab">Cash Margin Per Unit</div>
						<div className="val">
							£{calculatePORAndCashMargin().cashMargin.toFixed(2)}
						</div>
					</div>
				</div>
				<div className="col-sm-4 push-sm-1 col-md-10 col-lg-4">
					<div className="outfield">
						<div className="lab">POR</div>
						<div className="val">
							{calculatePORAndCashMargin().por.toFixed(2)}%
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PORCalculator;
