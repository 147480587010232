import React from "react";

import del from "../assets/del.svg";

import { AppConstants } from "../misc/Constants";
import AddToCart from "./addToCart";
import ProductSticker from "./ProductSticker";
import offerSticker from "../assets/offer.svg";
import rclubSticker from "../assets/rclub.svg";
import ProductImage from "./product-image";

const SingleCartProduct = ({
	product,
	handleClickonAdd,
	handleClickOnRemove,
}) => {
	return (
		<div className="prod">
			<div className="photo">
				<ProductImage
					supplierCode={product.supplier}
					productCode={product.code}
					largeImage={product.largeImage}
					missingImage={product.missingImage}
				/>

				<ProductSticker
					product={product}
					offerImage={offerSticker}
					rclubImage={rclubSticker}
				/>
			</div>

			<div className="detail">
				<div className="title">{product.name}</div>
				<div className="pad5-t">
					<div>
						{product.packsize} x {product.packsize_desc}
					</div>
				</div>
				<div className="price-row">
					<div className="col-md-6 txt-md-l">SKU: {product.code}</div>
					<div className="col-md-6 txt-md-r">
						<span className="amount">
							{AppConstants.POUND_SIGN + product.price}
						</span>
					</div>
					<div className="eor"></div>
				</div>

				<div className="pad5-t">
					<button className="del" onClick={handleClickOnRemove}>
						<img width="16px" height="16px" src={del} />
					</button>
					<AddToCart
						quantity={product.qty}
						listedInCart={true}
						onAddClicked={handleClickonAdd}
					/>
				</div>
			</div>
			<div className="eor"></div>
		</div>
	);
};

export default SingleCartProduct;
