import React, { useRef, useState } from "react";
import logo from "../assets/logo.svg";
import backImage from "../assets/back-icon.png";
import { UserService } from "../Services/UserService";
import LoadingIndicator from "./loadingIndicator";

const ExistingCustomerRegistration = () => {
	const [salesTobacco, setSalesTobacco] = useState(false);
	const [salesAlcohol, setSalesAlcohol] = useState(false);
	const [error, setError] = useState(null);
	const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);

	const [agreeToTerms, setAgreeToTerms] = useState(false);
	const accountNumberFieldRef = useRef(null);
	const emailFieldRef = useRef(null);
	const postcodeFieldRef = useRef(null);

	const handleSubmit = () => {
		if (
			accountNumberFieldRef.current.value.length == 0 ||
			emailFieldRef.current.value.length == 0 ||
			postcodeFieldRef.current.value.length == 0
		) {
			setError("Please fill all the required fields");
		} else if (agreeToTerms == false) {
			setError(
				"You cannot register with UWGL if you don't agree to Terms & Conditions, Privacy Policy, or End User License Agreement"
			);
		} else {
			setError(null);
			setShowLoadingIndicator(true);
			UserService.registerExistingCustomer(
				accountNumberFieldRef.current.value,
				emailFieldRef.current.value,
				postcodeFieldRef.current.value,
				salesAlcohol ? "1" : "0",
				salesTobacco ? "1" : "0",
				(successMessage, error) => {
					setShowLoadingIndicator(false);
					if (error != null) {
						setError(error);
					} else {
						alert(successMessage);
					}
				}
			);
		}
	};
	return (
		<>
			<div class="container">
				<div id="signreg" class="formbox">
					<div class="pad-b">
						<h1 class="pad20-t pad20-b txt-md-c">
							Existing Customer Registration
						</h1>
						<p class="txt-md-c pad-b">Fill in the form below to get started</p>
						<div class="form1 form-width1">
							<div class="pad-tb field">
								<div class="lab">Account No:</div>
								<div class="val">
									<input
										class="input jsfocus"
										type="numeric"
										ref={accountNumberFieldRef}
										onChange={() => {
											setError(null);
										}}
									/>
								</div>
							</div>
							{/* <div class="pad-tb field">
								<div class="lab">Branch:</div>
								<div class="val">
									<input class="input jsfocus" type="text" />
								</div>
							</div> */}
							<div class="pad-tb field">
								<div class="lab">Post Code:</div>
								<div class="val">
									<input
										onChange={() => {
											setError(null);
										}}
										class="input jsfocus"
										type="text"
										ref={postcodeFieldRef}
									/>
								</div>
							</div>
							{/* <div class="pad-tb field">
								<div class="lab">Mobile Number:</div>
								<div class="val">
									<input
										class="input jsfocus"
										type="tel"
										pattern="[0-9]{4}-[0-9]{3}-[0-9]{7}"
									/>
								</div>
							</div> */}
							<div class="pad-tb field">
								<div class="lab">Email:</div>
								<div class="val">
									<input
										class="input jsfocus"
										type="email"
										ref={emailFieldRef}
										onChange={() => {
											setError(null);
										}}
									/>
								</div>
							</div>
							<div class="pad-tb field">
								<div class="col-md-8">Do you sell Tobacco?</div>
								<div class="col-md-4">
									<input
										class="pad-l"
										name="tobacco1"
										type="radio"
										checked={salesTobacco}
										onClick={() => {
											setSalesTobacco(1);
										}}
									/>
									Yes{" "}
									<input
										class="pad-l"
										name="tobacco1"
										type="radio"
										checked={!salesTobacco}
										onClick={() => {
											setSalesTobacco(0);
										}}
									/>
									No
								</div>
								<div class="eor"></div>
							</div>
							<div class="pad-tb field">
								<div class="col-md-8">Do you sell Alcohol?</div>
								<div class="col-md-4">
									<input
										class="pad-l"
										name="alcohol1"
										type="radio"
										checked={salesAlcohol}
										onClick={() => {
											setSalesAlcohol(1);
										}}
									/>
									Yes{" "}
									<input
										class="pad-l"
										name="alcohol1"
										type="radio"
										checked={!salesAlcohol}
										onClick={() => {
											setSalesAlcohol(0);
										}}
									/>
									No
								</div>
								<div class="eor"></div>
							</div>

							{/* <div class="pad-tb field">
								<div class="col-md-8 line2">
									Security Code?<br></br>What is seven plus one?
								</div>
								<div class="col-md-4">
									<input class="input jsfocus txt-md-c" type="email" />
								</div>
								<div class="eor"></div>
							</div> */}
							<div class="pad-tb field field-lg">
								<input
									type="checkbox"
									checked={agreeToTerms}
									onClick={() => {
										setError(null);
										setAgreeToTerms(!agreeToTerms);
									}}
								/>{" "}
								I agree to the Term &amp; Conditions. Privacy Policy, EULA.
							</div>
							<div class="pad-tb field">
								{/* <div class="col-md-5">
									<input
										type="button"
										class="btn btn-full btn-white"
										value="Cancel"
									/>
								</div> */}
								<div className="pad-tb field">
									{error && <div style={{ color: "red" }}>{error}</div>}
								</div>
								{showLoadingIndicator ? (
									<LoadingIndicator />
								) : (
									<div class="push-md-3 col-md-5">
										<input
											type="submit"
											class="btn btn-full"
											value="Register"
											onClick={handleSubmit}
										/>
									</div>
								)}
								<div class="eor"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ExistingCustomerRegistration;
