import React, { useState, useEffect } from "react";
import BraintreeUI from "./BraintreeUI";

import backImage from "../assets/back-icon.png";

import { UserService } from "../Services/UserService";
import { CartService } from "../Services/CartService";
import LoadingIndicator from "./loadingIndicator";
import TimeSlotAndPaymentMethod from "./TimeSlotAndPaymentMethod";
import OrderCompleted from "./order_completed";
import { Link } from "react-router-dom";
import { Events } from "../classes/AppEnum";
import eventBus from "../classes/EventBus";
import SingleProduct from "./Single-Product";
import { ProductService } from "../Services/ProductService";

const Checkout = ({ onSuccessfulCheckout }) => {
	const [clientToken, setClientToken] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [timeSlots, setTimeSlots] = useState([]);

	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedSlot, setSelectedSlot] = useState(null);
	const [orderPlaced, setOrderPlaced] = useState(false);
	const [orderSuccessfulMsg, setOrderSuccessfulMsg] = useState("");
	const [advertisedProducts, setAdvertisedProducts] = useState([]);
	const [cartTotalAmount, setCartTotalAmount] = useState(null);

	const getPaymentMethods = () => {
		setIsLoading(true);
		UserService.getAssignedPaymentMethods(
			(clientToken, paymentMethods, timeSlots, error) => {
				setIsLoading(false);
				if (error != null) {
					alert(error);
					return;
				}
				setClientToken(clientToken);
				setTimeSlots(timeSlots);
				setPaymentMethods(paymentMethods);
				setSelectedPaymentMethod(null);
			}
		);
	};

	const getCartTotalAmount = () => {
		CartService.getCartTotal(
			(numberOfDifferentProducts, subtotal, vat, numberOfCases, error) => {
				if (error == null) {
					setCartTotalAmount(
						parseFloat(parseFloat(subtotal) + parseFloat(vat)).toFixed(2)
					);
				}
			}
		);
	};
	const getAdvertisedProducts = () => {
		ProductService.getProductsByProductCodes(
			"26324,28283",
			(products, error) => {
				if (products != null) {
					setAdvertisedProducts(products);
				}
			}
		);
	};
	const checkout = (
		nonce,
		paymentMethodId,
		collectionDate,
		collectionSlot,
		collectionDay
	) => {
		setIsLoading(true);
		CartService.checkout(
			nonce,
			paymentMethodId,
			collectionDate,
			collectionSlot,
			collectionDay,
			(msg, error) => {
				setIsLoading(false);
				if (error != null) {
					alert(error);
				} else {
					setOrderSuccessfulMsg(msg);
					setOrderPlaced(true);
					// onSuccessfulCheckout();
				}
			}
		);
	};

	useEffect(() => {
		eventBus.on(Events.productQuantityChangedInCart, (data) => {
			if (data.product.qty > 0) {
			} else {
			}
		});

		getPaymentMethods();
		getCartTotalAmount();
		getAdvertisedProducts();
		return function cleanup() {
			eventBus.remove(Events.productQuantityChangedInCart);
		};
	}, []);
	return (
		<>
			{orderPlaced ? (
				<OrderCompleted
					amount={cartTotalAmount}
					msg={orderSuccessfulMsg}
					onContinueToHomeClick={() => {}}
				/>
			) : (
				<section id="checkout" className="container pad20-t">
					<div className="prod-left-pane">
						<div className="filter prod-list grid-view">
							{advertisedProducts.length > 0 && (
								<SingleProduct
									title="Advertised Product"
									product={advertisedProducts[0]}
									listedInCart={false}
									listedInSearchResults={false}
								/>
							)}
						</div>
					</div>

					<div className="prod-left-pane right">
						<div className="filter prod-list grid-view">
							{advertisedProducts.length > 1 && (
								<SingleProduct
									title="Advertised Product"
									product={advertisedProducts[1]}
									listedInCart={false}
									listedInSearchResults={false}
								/>
							)}
						</div>
					</div>

					<div className="pad-b checkout">
						<div className="txt-md-c box960">
							<h1 className="pad20-b">
								<div className="col-md-3 txt-md-l">
									<Link to="/" className="back">
										<img className="pad5-r" src={backImage} />
										HOME
									</Link>
								</div>
								<div className="col-md-6">Check Out</div>
								<div className="eor"></div>
							</h1>

							{isLoading ? (
								<LoadingIndicator />
							) : (
								<div className="form1 txt-md-l form-width1">
									{clientToken != null &&
									selectedPaymentMethod != null &&
									selectedPaymentMethod.id == 3 ? (
										<BraintreeUI
											amount={cartTotalAmount}
											clientToken={clientToken}
											onGettingNonce={(nonce) => {
												checkout(
													nonce,
													selectedPaymentMethod.id,
													selectedDate != null ? selectedDate.date : null,
													selectedSlot,
													selectedDate != null ? selectedDate.dayName : null
												);
											}}
										/>
									) : (
										<TimeSlotAndPaymentMethod
											amount={cartTotalAmount}
											paymentMethods={paymentMethods}
											timeSlotsForClickAndCollect={timeSlots}
											onClickOnCheckout={(
												paymentMethod,
												selectedDate,
												selectedSlot
											) => {
												if (paymentMethod.id != 3) {
													checkout(
														"",
														paymentMethod.id,
														selectedDate != null ? selectedDate.date : null,
														selectedSlot,
														selectedDate != null ? selectedDate.dayName : null
													);
												} else {
													setSelectedPaymentMethod(paymentMethod);
													setSelectedDate(selectedDate);
													setSelectedSlot(selectedSlot);
												}
											}}
										/>
									)}
								</div>
							)}
						</div>
					</div>
				</section>
			)}
		</>
	);
};

export default Checkout;
