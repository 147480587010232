import React from "react";

import deepcutOffersImage from "../assets/deepcut-offers.svg";
import brochureImage from "../assets/data/brochure.png";

const BrochureLink = ({ onClick }) => {
	return (
		<article className="col-sm-6">
			<div className="pad-lr">
				<div className="broapp">
					<div className="photo">
						<a>
							<img src={brochureImage} onClick={onClick} />
						</a>
					</div>

					<div className="content">
						<div className="pad-b">
							<img src={deepcutOffersImage} />
						</div>
						<div className="text pad-tb">
							<strong>3 week offer</strong> available from Monday 24th April
							2023 to Sunday 14th May 2023
						</div>
						<div className="pad-t">
							<button className="btn" onClick={onClick}>
								View The Deals
							</button>
						</div>
					</div>

					<div className="eor"></div>
				</div>
			</div>
		</article>
	);
};

export default BrochureLink;
