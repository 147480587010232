import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import B1 from "./b1";
import B3A from "./b3a";
import B5A from "./b5a";
import B5B from "./b5b";
import B3B from "./b3b";
import B5C from "./b5c";
import B5D from "./b5d";
import { useSupplier } from "./SupplierShop"; // Assuming you have this hook to get supplier code

const ThemeSeven = ({ images, onImageSelected }) => {
	const [imagesHash, setImagesHash] = useState({});
	const supplierCode = useSupplier() || ""; // Ensure supplierCode is not undefined

	useEffect(() => {
		let updatedImagesHash = {};
		images.forEach((image) => {
			if (image.key) {
				updatedImagesHash[image.key] = image;
			}
		});
		setImagesHash(updatedImagesHash);
	}, [images]);

	return (
		<>
			<Link to={`/supplierShop/${supplierCode}/${imagesHash.b1?.key || ""}`}>
				<B1
					onClick={() => {
						onImageSelected(imagesHash.b1?.key);
					}}
					imageUrl={imagesHash.b1?.imageFullPath || ""}
				/>
			</Link>
			<article className="pad-t">
				<div className="row5">
					<div className="col-md-6">
						<div className="pad5-lr">
							<div className="row5">
								<div className="col-md-6">
									<Link
										to={`/supplierShop/${supplierCode}/${
											imagesHash.b5a?.key || ""
										}`}
									>
										<B5A
											onClick={() => {
												onImageSelected(imagesHash.b5a?.key);
											}}
											imageUrl={imagesHash.b5a?.imageFullPath || ""}
										/>
									</Link>
								</div>
								<div className="col-md-6">
									<Link
										to={`/supplierShop/${supplierCode}/${
											imagesHash.b5b?.key || ""
										}`}
									>
										<B5B
											onClick={() => {
												onImageSelected(imagesHash.b5b?.key);
											}}
											imageUrl={imagesHash.b5b?.imageFullPath || ""}
										/>
									</Link>
								</div>
								<div className="eor"></div>
							</div>

							<Link
								to={`/supplierShop/${supplierCode}/${
									imagesHash.b3b?.key || ""
								}`}
							>
								<B3A
									onClick={() => {
										onImageSelected(imagesHash.b3b?.key);
									}}
									imageUrl={imagesHash.b3b?.imageFullPath || ""}
								/>
							</Link>
						</div>
					</div>
					<div className="col-md-6">
						<Link
							to={`/supplierShop/${supplierCode}/${imagesHash.b3a?.key || ""}`}
						>
							<B3A
								onClick={() => {
									onImageSelected(imagesHash.b3a?.key);
								}}
								imageUrl={imagesHash.b3a?.imageFullPath || ""}
							/>
						</Link>

						<div className="pad5-lr">
							<div className="row5">
								<div className="col-md-6">
									<Link
										to={`/supplierShop/${supplierCode}/${
											imagesHash.b5c?.key || ""
										}`}
									>
										<B5C
											onClick={() => {
												onImageSelected(imagesHash.b5c?.key);
											}}
											imageUrl={imagesHash.b5c?.imageFullPath || ""}
										/>
									</Link>
								</div>
								<div className="col-md-6">
									<Link
										to={`/supplierShop/${supplierCode}/${
											imagesHash.b5d?.key || ""
										}`}
									>
										<B5D
											onClick={() => {
												onImageSelected(imagesHash.b5d?.key);
											}}
											imageUrl={imagesHash.b5d?.imageFullPath || ""}
										/>
									</Link>
								</div>
								<div className="eor"></div>
							</div>
						</div>
					</div>

					<div className="eor"></div>
				</div>
			</article>
		</>
	);
};

export default ThemeSeven;
