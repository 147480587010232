import React, { createContext, useContext, useEffect, useState } from "react";
import Theme from "./theme";
import { SupplierService } from "../Services/SupplierService";
import ProductsGrid from "./productsGrid";
import { useParams } from "react-router-dom";

const SupplierContext = createContext();

const SupplierShop = () => {
	const { supplierCode } = useParams();
	const { supplierShopImageKey } = useParams();
	const [supplier, setSupplier] = useState(null);

	const getSupplier = () => {
		if (supplierCode != null) {
			SupplierService.getSuppliers(supplierCode, (suppliers) => {
				if (suppliers.length > 0) {
					setSupplier(suppliers[0]);
				}
			});
		}
	};

	useEffect(() => {
		getSupplier();
	}, [supplierCode, supplierShopImageKey]);
	return (
		<SupplierContext.Provider value={supplierCode}>
			{supplier && (
				<ProductsGrid
					searchText=""
					supplier={supplier}
					supplierShopImageKey={supplierShopImageKey}
				/>
			)}
		</SupplierContext.Provider>
	);
};

export function useSupplier() {
	return useContext(SupplierContext);
}

export default SupplierShop;
