import React, { useState } from "react";
import { stores } from "../misc/constantData";
import { Link } from "react-router-dom";

const StoresDropDown = () => {
	const [storesDropDownIsHidden, setStoresDropDownIsHidden] = useState(true);

	return (
		<span
			className="mitem dropdown-tog dropdown-hov"
			onMouseEnter={() => {
				setStoresDropDownIsHidden(false);
			}}
			onMouseLeave={() => {
				setStoresDropDownIsHidden(true);
			}}
		>
			<span className="dropdown-x">Our Depots</span>
			{storesDropDownIsHidden || (
				<div className="dropdown">
					{stores.map((store) => {
						return (
							<Link key={store.id} className="item" to={"/store/" + store.name}>
								{store.name}
							</Link>
						);
					})}
				</div>
			)}
		</span>
	);
};

export default StoresDropDown;
