import React, { useState } from "react";

import checkmark from "../assets/checkmark.svg";

const B1 = ({ onClick, imageUrl }) => {
	const [selected, setSelected] = useState(false);
	return (
		<article
			className="supplier-banner-main checkmark"
			style={{ backgroundColor: "#220105", textAlign: "center" }}
			onClick={() => {
				setSelected(!selected);
				onClick();
			}}
		>
			{selected && <img width="100%" className="checkmark" src={checkmark} />}

			<img width="100%" className="b-1" src={imageUrl} />
		</article>
	);
};

export default B1;
