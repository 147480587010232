import React from "react";
const B2A = ({ imageUrl, onClick }) => {
	return (
		<div className="mar20-t mar-lr" onClick={onClick}>
			<a>
				<img width="100%" src={imageUrl} />
			</a>
		</div>
	);
};

export default B2A;
