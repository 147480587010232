import React from "react";
import { Link } from "react-router-dom";

const SubCategories = ({ category, subcategories, onClick }) => {
	return (
		<div className="item-group">
			<div id="option1" className="group2" style={{ display: "block" }}>
				{subcategories.map((subcategory) => {
					return (
						<Link
							key={subcategory.h2Id}
							to={
								"/products/category/" + category.h1Id + "/" + subcategory.h2Id
							}
							className="item promo"
						>
							{subcategory.h2Name}
						</Link>
					);
				})}
			</div>
		</div>
	);
};

export default SubCategories;
