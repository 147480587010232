import React, { useEffect, useState } from "react";
import { UserService } from "../Services/UserService";
import eventBus from "../classes/EventBus";
import { Events } from "../classes/AppEnum";
import showSubCats from "../assets/show-sub-cats.svg";

const OrderSettingsLink = ({ handleClickOnChange }) => {
	const [orderTypeName, setOrderTypeName] = useState("");
	const [orderTypeId, setOrderTypeId] = useState("");

	const [productTypeName, setProductTypeName] = useState("");

	const getUserPreferences = () => {
		UserService.getPreferences(
			(orderTypeName, productTypeName, orderTypeId, productTypeId, error) => {
				setOrderTypeName(orderTypeName);
				setOrderTypeId(orderTypeId);
				setProductTypeName(productTypeName);
			}
		);
	};
	useEffect(() => {
		eventBus.on(Events.UserPreferencesChanged, () => {
			getUserPreferences();
		});

		getUserPreferences();

		return function cleanup() {
			eventBus.remove(Events.UserPreferencesChanged);
		};
	}, []);
	return (
		<div className="txt-md-r">
			{UserService.isSignedIn() && (
				<>
					Displaying: <strong>{productTypeName}</strong> | Order type:
					<strong>{orderTypeName} </strong>
					<a
						className="change-orprotype"
						href="#"
						onClick={() => {
							handleClickOnChange(orderTypeId);
						}}
					>
						[Change]
					</a>
				</>
			)}
		</div>
	);
};

export default OrderSettingsLink;
