import React from "react";
import blankSticker from "../assets/sticker-blank.png";
const ProductSticker = ({ product, offerImage, rclubImage }) => {
	const getSticker = () => {
		if (product.scheme == "RC") {
			return rclubImage;
		}
		if (
			product.scheme == "SP" ||
			product.scheme == "NA" ||
			product.scheme == "TD"
		) {
			return offerImage;
		}
		return blankSticker;
	};
	return (
		<div className="sticker">
			<img src={getSticker()} />
		</div>
	);
};

export default ProductSticker;