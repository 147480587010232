import { useState, useRef } from "react";
import { UserService } from "../Services/UserService";
import LoadingIndicator from "./loadingIndicator";

const ForgotPassword = ({ onClickOnCancel }) => {
	const emailFieldRef = useRef(null);
	const accountNoFieldRef = useRef(null);
	const [showLoadingIndicator, setShowLoadingIndiator] = useState(false);
	const [error, setError] = useState(null);

	const handleTextFieldOnChange = () => {
		setError(null);
	};
	const handleSubmit = () => {
		if (emailFieldRef.current.value && accountNoFieldRef.current.value) {
			requestNewPassword(
				emailFieldRef.current.value,
				accountNoFieldRef.current.value
			);
		}
	};
	const requestNewPassword = (email, accountNo) => {
		setError(null);
		setShowLoadingIndiator(true);
		UserService.requestNewPassword(email, accountNo, (successMsg, error) => {
			setShowLoadingIndiator(false);
			if (error) {
				setError(String(error));
			} else {
				emailFieldRef.current.value = "";
				accountNoFieldRef.current.value = "";
				alert(successMsg);
			}
		});
	};
	return (
		<div className="pad-b">
			<div className="txt-md-c box500">
				<h1 className="line40">Forgot Password?</h1>
				<div className="pad-tb line18">
					If you have forgotten your password you need to fill in the two boxes
					below. The new password will then be sent to your email address.
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<div className="form1 txt-md-l form-width1">
						<div className="pad-tb field">
							<div className="lab">Email:</div>
							<div className="val">
								<input
									className="input jsfocus"
									type="email"
									ref={emailFieldRef}
									onChange={handleTextFieldOnChange}
								/>
							</div>
						</div>
						<div className="pad-tb field">
							<div className="lab">Account No:</div>
							<div className="val">
								<input
									className="input jsfocus"
									ref={accountNoFieldRef}
									onChange={handleTextFieldOnChange}
								/>
							</div>
						</div>
						<div className="pad-tb field">
							{error && <div style={{ color: "red" }}>{error}</div>}
						</div>
						{showLoadingIndicator ? (
							<LoadingIndicator />
						) : (
							<div className="pad-tb">
								{/* <div className="col-md-6 txt-md-l">
									<input
										className="btn btn120 btn-white"
										type="button"
										value="CANCEL"
										onClick={onClickOnCancel}
									/>
								</div> */}
								{/* <div className="col-md-6 txt-md-r"> */}
								<input className="btn btn120" type="submit" value="SUBMIT" />
								{/* </div> */}
								<div className="eor"></div>
							</div>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default ForgotPassword;
