import React, { useRef, useState, useEffect } from "react";
import LoadingIndicator from "./loadingIndicator";

const AddToCart = ({ quantity, listedInCart, onAddClicked }) => {
	const currentQuantityFieldRef = useRef(null);
	const [isLoading, setIsLoading] = useState(false);
	let currentQuantity = null;
	let isQuantityChangedByUser = false;

	const handleTextFieldChange = (value) => {
		currentQuantityFieldRef.current.value = value;
		currentQuantity = parseInt(value);
		isQuantityChangedByUser = true;
	};
	const handleQuantityFieldOnBlur = () => {
		currentQuantityFieldRef.current.value = quantity;
	};
	const handleAddClicked = () => {
		if (!currentQuantity && !isQuantityChangedByUser) {
			if (quantity == null) {
				currentQuantity = 1;
			} else {
				currentQuantity = parseInt(quantity);
			}
		}
		if (currentQuantity == parseInt(quantity) && !isQuantityChangedByUser) {
			currentQuantity = currentQuantity + 1;
		}
		if (isQuantityChangedByUser && !currentQuantity) {
			currentQuantity = 0;
		}
		setIsLoading(true);
		onAddClicked(currentQuantity);
	};
	useEffect(() => {
		setIsLoading(false);
		currentQuantity = quantity;
		if (currentQuantityFieldRef.current != null) {
			currentQuantityFieldRef.current.value = quantity;
		}
	}, [quantity]);
	return (
		<>
			{isLoading && <LoadingIndicator />}
			{isLoading || (
				<div
					className="form"
					style={
						listedInCart
							? {
									float: "right",
							  }
							: {}
					}
				>
					<input
						className={listedInCart ? "cartInput" : ""}
						style={listedInCart ? { float: "left" } : {}}
						type="text"
						defaultValue={quantity}
						onChange={(e) => handleTextFieldChange(e.target.value)}
						ref={currentQuantityFieldRef}
						onBlur={handleQuantityFieldOnBlur}
					/>

					<input
						className={listedInCart ? "update" : ""}
						value={quantity > 0 ? "Update" : "Add"}
						type="submit"
						onClick={handleAddClicked}
					/>
				</div>
			)}
		</>
	);
};;

export default AddToCart;
