import React from "react";
import { useEffect, useState } from "react";
import { ProductService } from "../Services/ProductService";
import LoadingIndicator from "./loadingIndicator";
import ProductNotFound from "./product_not_found";
import SingleImportedProduct from "./SingleImportedProduct";
const ImportedProducts = ({
	barcodes,
	quantities,
	onSuccessfulImportOfProducts,
	onClickOnPrevious,
}) => {
	const [products, setProducts] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isUpdatingCart, setIsUpdatingCart] = useState(false);
	const uniqueBarcodes = [...new Set(barcodes)];

	const getProductsForBarcodes = () => {
		setIsLoading(true);
		ProductService.getProductsWithBarcodes(
			String(uniqueBarcodes),
			(newProducts, cartInstructions, error) => {
				newProducts = newProducts.map((product) => {
					// if (product.qty == null) {
					// 	product.qty = 1;
					// }
					return product;
				});

				setProducts(newProducts);
				setIsLoading(false);
			}
		);
	};
	const addToCart = () => {
		setIsUpdatingCart(true);
		ProductService.addProductsToCart(products, (error) => {
			setIsUpdatingCart(false);
			if (error) {
				alert(error);
			} else {
				alert("Successfully added to cart");
				onSuccessfulImportOfProducts();
			}
		});
	};
	const performIntersection = (arr1, arr2) => {
		// converting into Set
		const setA = new Set(arr1);
		const setB = new Set(arr2);

		let intersectionResult = [];

		for (let i of setB) {
			if (setA.has(i)) {
				intersectionResult.push(i);
			}
		}

		return intersectionResult;
	};
	useEffect(() => {
		getProductsForBarcodes();
		return () => {};
	}, []);
	return (
		<>
			{isLoading ? (
				<LoadingIndicator className="loader" text1="Fetching products ..." />
			) : (
				<div id="import" className="txt-md-l box960  form1">
					<div className="table-info">Products imported from file</div>

					<table
						className="prod-table"
						width="100%"
						cellpadding="0"
						cellspacing="0"
						border="0"
					>
						{uniqueBarcodes.map((barcode) => {
							let filteredProducts = products.filter((product) =>
								product.barcode.split(" ").includes(barcode)
							);
							if (filteredProducts.length > 0) {
								let product = filteredProducts[0];
								const barcode = performIntersection(
									filteredProducts[0].barcode.split(" "),
									uniqueBarcodes
								);
								product.qty = product.qty ?? quantities[barcode];
								return (
									<SingleImportedProduct
										product={filteredProducts[0]}
										barcode={performIntersection(
											filteredProducts[0].barcode.split(" "),
											uniqueBarcodes
										)}
									/>
								);
							} else {
								return <ProductNotFound barcode={barcode} />;
							}
						})}
					</table>
					<div className="table-info-b">
						<div className="col-md-4 txt-md-l">
							<span className="matched">Matched items: {products.length} </span>
							<span className="unmatched">
								Unmatched items: {uniqueBarcodes.length - products.length}
							</span>
						</div>
						<div className="col-md-8 txt-md-r info">
							On clicking finish, all the above matched items will be
							transferred to your trolley for checkout
						</div>
						<div className="eor"></div>
					</div>
					<div className="pad-t txt-md-r">
						{isUpdatingCart ? (
							<LoadingIndicator />
						) : (
							<>
								<input
									type="button"
									value="Previous"
									className="btn btn120 btn-white"
									onClick={onClickOnPrevious}
								/>
								{"   "}
								<input
									className="btn btn120"
									type="button"
									value="FINISH"
									onClick={() => addToCart()}
								/>
							</>
						)}
					</div>
				</div>
			)}
		</>
	);
};

export default ImportedProducts;
