import React from "react";

const Hello = (props) => {
	return (
		<div onClick={props.onClick}>
			<div className="line1">{props.title}</div>
			<div className="line2">Account &amp; Lists</div>
		</div>
	);
};

export default Hello;
