import React from "react";
import { AccountLink } from "../classes/AppEnum";
import { Link } from "react-router-dom";
const AccountsAndLinks = ({ onLogoutClick }) => {
	return (
		<div className="dropdown">
			<div className="col">
				<h3 className="txt-md-l">Your Account</h3>
				<div className="ulinewrap">
					{AccountLink.getAll().map((link, index, links) => {
						if (index >= links.length - 1) {
							return <div key={0} />;
						}
						return (
							<div key={link.id} className="ulinerow">
								<Link className="uline" to={link.url}>
									{link.title}
								</Link>
							</div>
						);
					})}
				</div>
				<div className="footer">
					<div className="mydetail">
						<Link className="uline" to={AccountLink.getAll().pop().url}>
							{AccountLink.getAll().pop().title}
						</Link>
					</div>
					<div className="logout">
						<a className="uline" href="#" onClick={onLogoutClick}>
							Logout
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AccountsAndLinks;
