import React, { useEffect, useState } from "react";
import { AppConstants } from "../misc/Constants";
import { ProductService } from "../Services/ProductService";
import LoadingIndicator from "./loadingIndicator";

const ProductNotFound = ({ barcode }) => {
	const [substituteProduct, setSubstitueProduct] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const getSubstituteProduct = () => {
		setIsLoading(true);
		ProductService.getSubstituteProduct(barcode, (products, error) => {
			setIsLoading(false);
			if (products.length > 0) {
				setSubstitueProduct(products[0]);
			}
		});
	};

	useEffect(() => {
		// getSubstituteProduct();
		return () => {};
	}, []);
	return (
		<tr className="notfound">
			<td className="x">
				<img src="images/x3.svg" />
			</td>
			<td className="notfoundwrap" colSpan="6">
				<table width="100%" cellpadding="0" cellspacing="0" border="0">
					<tr className="notfoundprod">
						<td className="notfound">Product Not Found</td>
						<td className="barcode">Barcode: {barcode}</td>
						<td className="eor"></td>
					</tr>
					{isLoading && <LoadingIndicator />}
					{substituteProduct && (
						<tr className="substitute">
							<td colSpan="2">
								<div className="wrap">
									<h4>Substitute</h4>
									<table
										width="100%"
										cellpadding="0"
										cellspacing="0"
										border="0"
									>
										<tr>
											<td className="photo">Image</td>
											<td className="desc">
												<div className="desc1">Product Name Here</div>
												<div className="desc2">Case of 6 x 225ml</div>
												<div className="desc2">SKU: 23535</div>
											</td>
											<td className="barcode">Barcode: 1234567890123</td>
											<td className="qtylab">Qty:</td>
											<td className="addcart-form">
												<form>
													<input className="addqty" type="text" />
													<input
														className="addcart"
														value="UPDATE"
														type="button"
													/>
													<div className="eor"></div>
												</form>
											</td>
											<td className="price">£9.99</td>
											<td className="eor"></td>
										</tr>
									</table>
								</div>
							</td>
						</tr>
					)}
				</table>
			</td>
			<td className="eor"></td>
		</tr>
	);
};

export default ProductNotFound;
