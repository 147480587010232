import React from "react";
// import gocardless from "gocardless-nodejs";
// const gocardless = require("gocardless-nodejs");

// import GoCardLessConstants from "gocardless-nodejs/constants";
// const constants = require("gocardless-nodejs/constants");

const MakePayment = () => {
	// const client = gocardless(
	// 	"sandbox_vgr6f6Q3BVkYx-0lohgKP_svgtFe5uawUVO1XAu0",
	// 	constants.Environments.Sandbox
	// );

	async function handleClickOnPay() {
		// const listResponse = await fetch(client.customers.list()).then(
		// 	(listResponse) => {
		// 		const customers = listResponse.customers;
		// 		console.log("Customers: " + customers);
		// 	}
		// );
	}
	return (
		<div>
			<button title="Pay 500" onClick={handleClickOnPay}></button>
		</div>
	);
};
export default MakePayment;
