import React from "react";
import { AppConstants } from "../misc/Constants";
import ProductSticker from "./ProductSticker";
import AddToCart from "./addToCart";
import { UserService } from "../Services/UserService";
import offerBanner from "../assets/offer.svg";
import rclubBanner from "../assets/rclub.svg";
import ProductImage from "./product-image";

const SingleProductInSearchResults = ({ product, handleClickonAdd }) => {
	return (
		<>
			<div className="pad-tb bdr-b prod">
				<div className="prod-img">
					<ProductImage
						supplierCode={product.supplier}
						productCode={product.code}
						largeImage={product.largeImage}
						missingImage={product.missingImage}
					/>
				</div>

				<div className="prod-desc-wrap">
					<div className="prod-desc">{product.name}</div>
					<div className="prod-detail">
						<div className="pack-size">
							{"Case of " + product.packsize + " x " + product.packsize_desc}
						</div>
						<div className="pack-size">{"SKU: " + product.code}</div>

						{UserService.isSignedIn() && (
							<div className="price">
								{AppConstants.POUND_SIGN + product.price}
							</div>
						)}
					</div>
					<ProductSticker
						product={product}
						offerImage={offerBanner}
						rclubImage={rclubBanner}
					/>
					{UserService.isSignedIn() && (
						<div className="addcart-form">
							<AddToCart
								quantity={product.qty}
								listedInCart={false}
								onAddClicked={handleClickonAdd}
							/>
						</div>
					)}

					<div className="eor"></div>
				</div>

				<div className="eor"></div>
			</div>
		</>
	);
};

export default SingleProductInSearchResults;
