import { APIService } from "./APIService";

export const BannerService = {
	addBannerImpression: function (bannerId, callback) {
		APIService.post(
			"add_top_banner_impression.php",
			{ bannerId: bannerId },
			(jsonResponse, error) => {
				callback(error);
			}
		);
	},
	addBannerClick: function (bannerId, callback) {
		APIService.post(
			"add_top_banner_clicked.php",
			{ bannerId: bannerId },
			(jsonResponse, error) => {
				callback(error);
			}
		);
	},
};
