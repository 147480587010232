import React, { useEffect } from "react";
import MidBar from "./midBar";
import TopBar from "./topbar";
import Filterbar from "./filterbar";
import { ScrollDirections } from "../classes/AppEnum";

const Header = ({
	scrollDirection,
	refreshCount,
	specialFilters,
	onCartClick,
	handleClickOnOrderSettingChange,
	onLogoutClick,
	onAccountLinksDisplay,
	displayCategories,
	handleSignin,
}) => {
	useEffect(() => {
		return () => {};
	}, [refreshCount, scrollDirection]);
	return (
		<header className="container">
			{scrollDirection == ScrollDirections.UP && <TopBar />}
			<MidBar
				refresh={refreshCount}
				onCartClick={onCartClick}
				onLogoutClick={onLogoutClick}
				onAccountLinksDisplay={onAccountLinksDisplay}
				displayCategories={displayCategories}
				handleSignin={handleSignin}
			/>
			<Filterbar
				specialFilters={specialFilters}
				handleClickOnOrderSettingChange={handleClickOnOrderSettingChange}
				onAccountLinksDisplay={onAccountLinksDisplay}
				onLogoutClick={onLogoutClick}
			/>
		</header>
	);
};

export default Header;
