import React, { useState } from "react";

const FileBrowser = ({ onClickOnNext }) => {
	const [fileData, setFileData] = useState(null);

	const readFile = (e) => {
		e.preventDefault();
		const reader = new FileReader();
		reader.onload = async (e) => {
			const text = e.target.result;
			const rows = text.split(/\r?\n/);
			let matrix = [];
			rows.forEach((row) => {
				const arr = row.split(",");
				if (arr.length > 0) {
					matrix.push(arr);
				}
			});
			if (matrix.length > 0) {
				setFileData(matrix);
			}
		};
		reader.readAsText(e.target.files[0]);
	};

	return (
		<div className="pad">
			<h3 className="pad-tb">Import CSV or TXT file</h3>
			<div className="pad-tb field">
				<div className="lab pad5-b">Upload file:</div>
				<div className="val">
					<input
						accept=".txt, .csv"
						className="jsfocus"
						type="file"
						onChange={readFile}
					/>
				</div>
				<div className="finfo">import products from CSV or TXT files</div>
			</div>
			<div className="pad-t">
				<input
					type="button"
					value="Previous"
					className="btn btn120 btn-white"
				/>
				<input
					type="button"
					value="Next"
					className="mar20-l btn btn120"
					onClick={(e) => {
						onClickOnNext(fileData);
					}}
				/>
			</div>
		</div>
	);
};

export default FileBrowser;
