import React from "react";
import StoresDropDown from "./storesDropDown";
import trackOrderImage from "../assets/track-order.png";
import { TopbarLinks } from "../classes/AppEnum";
import { Utils } from "../Services/Utils";
import { Link } from "react-router-dom";

import $ from "jquery";

const TopBar = () => {
	const handleClickOnLink = (id) => {
		if (id == TopbarLinks.GET_THE_APP.id) {
			//Get the app is clicked
			Utils.directToApp();
		} else if (id == TopbarLinks.DIRECT_TO_STORE.id) {
			//Direct to store is clicked
			Utils.directToStore();
		}
	};

	const xtopbar = () => {
		$("header .topbar").animate({ left: "-100%" }).show();
	};

	return (
		<div className="topbar">
			<div onClick={xtopbar} className="x">
				✖
			</div>
			<div className="col-lg-8 txt-md-l">
				{TopbarLinks.getAll().map((link) => {
					return (
						<Link
							key={link.id}
							className="mitem"
							to={link.url}
							id={link.id}
							onClick={() => {
								handleClickOnLink(link.id);
							}}
						>
							{link.text}
						</Link>
					);
				})}

				<StoresDropDown />
			</div>
		</div>
	);
};

export default TopBar;
