import { useEffect, useState } from "react";

import ProductImage from "./product-image";
import { useParams } from "react-router-dom";
import LoadingIndicator from "./loadingIndicator";
import { CartService } from "../Services/CartService";

const OrderDetail = () => {
	const { orderNo, customerCode } = useParams();
	const [isLoading, setIsLoading] = useState(false);
	const [orderItems, setOrderItems] = useState([]);

	const getOrderItems = () => {
		setIsLoading(true);
		CartService.getOrderDetails(orderNo, (orderItems, error) => {
			setIsLoading(false);
			if (error != null) {
				alert(error);
			} else {
				setOrderItems(orderItems);
			}
		});
	};

	useEffect(() => {
		getOrderItems();
	}, [orderNo, customerCode]);
	return (
		<>
			{isLoading ? (
				<LoadingIndicator
					text1="Loading order details ..."
					className="loader"
				/>
			) : (
				<section id="order-detail" className="container">
					<article className="line40 pad20-t">
						<div className="col-md-6 txt-md-r">
							<a href="#">Displaying {orderItems.length} items</a>
						</div>
						<div className="eor"></div>
					</article>
					<article></article>
					<article className="pad20-t restbl">
						<table
							className="table2"
							width="100%"
							cellpadding="0"
							cellspacing="0"
							border="0"
						>
							<tr>
								<td className="ofield" colSpan={10}>
									<div className="col-md-4 txt-md-l">
										Date: <span className="val">{"4/11/2023"}</span>
									</div>
									<div className="col-md-4 txt-md-c">
										Order No:{" "}
										<span className="val">
											{orderNo} {customerCode}
										</span>
									</div>
									<div className="col-md-4 txt-md-r">
										Invoice No: <span className="val">{orderNo}</span>
									</div>
								</td>
							</tr>
							<tr className="ofield">
								<th className="txt-md-l">SKU</th>
								<th width="100px" className="txt-md-c">
									&nbsp;
								</th>
								<th className="txt-md-l">Description</th>
								<th className="txt-md-r">Pack Size</th>
								<th className="txt-md-r">Price</th>
								<th className="txt-md-r">Qty</th>
								<th className="txt-md-r">Qty Ordered</th>
								<th className="txt-md-r">Ext. Price</th>
								<th className="txt-md-r">VAT</th>
								<th className="txt-md-r">RRP</th>
								<th className="txt-md-r">Total</th>
								{/* <th className="txt-md-r">Availablity</th> */}
							</tr>
							{orderItems.map((item, index) => {
								return (
									<tr key={index}>
										<td className="txt-md-l">{item.item}</td>
										<td className="txt-md-c">
											<div height={150} width={150}>
												<ProductImage
													supplierCode={item.supplier}
													productCode={item.code}
													height={120}
													width={120}
													largeImage={item.item + "_500.jpeg"}
													missingImage=""
												/>
											</div>
										</td>
										<td className="txt-md-l">{item.description}</td>
										<td className="txt-md-r">
											{item.pack * item.uos} x {item.pack_description}
										</td>
										<td className="txt-md-r">£{item.sell}</td>
										<td className="txt-md-r">{item.Qty_Sold}</td>
										<td className="txt-md-r">{item.qty_ordered}</td>
										<td className="txt-md-r">£{item.TotalValue}</td>
										<td className="txt-md-r">£{item.Total_Value_Vat}</td>
										<td className="txt-md-r">£{item.rrp}</td>
										<td className="txt-md-r">£{item.Total_Value_Goods}</td>
										{/* <td className="txt-md-r available">Available</td> */}
									</tr>
								);
							})}
						</table>
					</article>
				</section>
			)}
		</>
	);
};

export default OrderDetail;
