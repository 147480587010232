import React, { useEffect, useState } from "react";
import moreIcon from "../assets/more-icon.svg";
import { Link } from "react-router-dom";

const Categories = ({
	specialFilters,
	assignedCategories,
	onFocus,
	onFocusEnd,
	onFocusOnSpecialFilter,
}) => {
	return (
		<div className="item-group">
			{specialFilters.map((specialFilter) => {
				return (
					<Link
						key={specialFilter.id}
						data-target="option2"
						className="item"
						to={"/products/specialFilters/" + specialFilter.NAME}
						onMouseEnter={(e) => {
							onFocusOnSpecialFilter(specialFilter);
						}}
					>
						{specialFilter.NAME}
					</Link>
				);
			})}
			{assignedCategories.map((category) => {
				return (
					<div className="main">
						<Link
							key={category.h1Id != null ? category.h1Id : category.h2Id}
							data-target="option2"
							className="item"
							to={
								"/products/category/" +
								(category.h1Id != null && category.h1Id != undefined
									? category.h1Id
									: "5" + "/" + "4") +
								"/"
							}
							onMouseEnter={() => {
								onFocus(category);
							}}
							onMouseLeave={() => {
								onFocusEnd(category);
							}}
						>
							{category.h1Name ?? category.h2Name}
						</Link>
						<img
							className="more-icon"
							src={moreIcon}
							onClick={() => {
								onFocus(category);
							}}
						/>
						<div className="eor"></div>
					</div>
				);
			})}
		</div>
	);
};

export default Categories;
