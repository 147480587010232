import React, { useLayoutEffect, useState, useEffect } from "react";
import SingleProduct from "./Single-Product";
import PreviousArrow from "./Previous-Arrow";
import NextArrow from "./Next-Arrow";
import { ProductService } from "../Services/ProductService";
import { AppConstants } from "../misc/Constants";
import eventBus from "../classes/EventBus";
import { Events } from "../classes/AppEnum";


const HorizontalProductsList = ({ SpecialFilter }) => {
	const { innerWidth: width, innerHeight: height } = window;

	const [products, setProducts] = useState([]);

	const productWidth = window.innerWidth > 480 ? 218 : 143;
	const [left, setLeft] = useState(0);
	const productsListWidth =
		products.length == 0 ? 218 : products.length * productWidth;

	const handleClickOnPrevious = () => {
		if (left <= -productWidth) {
			setLeft(left + productWidth);
		}
	};
	const handleClickOnNext = () => {
		const totalLeftPossible = productsListWidth - width;

		if (-left < totalLeftPossible) {
			setLeft(left - productWidth);
		}
	};
	var touchx1;
	const fingerstart = (event) => {
		touchx1 = event.touches[0].clientX;
	}
	const fingerend = (event) => {
		var touchx2 = event.changedTouches[0].clientX;
		if(touchx1 < touchx2)
		{
			handleClickOnPrevious();
		}
		if(touchx1 > touchx2)
		{
			handleClickOnNext();
		}
	}
	
	const getProducts = () => {
		setProducts([]);
		ProductService.getProducts(
			SpecialFilter,
			"",
			null,
			null,
			null,
			null,
			"",
			AppConstants.Products_Limit,
			null,
			"",
			null,
			(products, cartInstructions, error) => {
				setProducts(products);
			}
		);
	};
	useEffect(() => {
		eventBus.on(Events.UserLoggedOut, () => {
			getProducts();
		});
		eventBus.on(Events.UserPreferencesChanged, () => {
			getProducts();
		});

		getProducts();

		return function cleanup() {
			eventBus.remove(Events.UserPreferencesChanged);
		};
	}, [SpecialFilter]);

	return (
		<>
			{products.length > 0 && (
				<section className="featprod prod-list grid-view">
					<h1 className="pad20-b">{SpecialFilter.NAME}</h1>
					<article className="carousel">
						<PreviousArrow onClickHandler={handleClickOnPrevious} />
						<NextArrow onClickHandler={handleClickOnNext} />

						<div className="clipper"  onTouchStart={fingerstart} onTouchEnd={fingerend}>
							<div
								className="list"
								style={{
									left,
									transition: "left 0.25s linear",
									width: productsListWidth,
								}}
							>
								{products.map((product) => {
									return <SingleProduct key={product.id} product={product} />;
								})}
							</div>
						</div>
					</article>
				</section>
			)}
		</>
	);
};
export default HorizontalProductsList;

function useWindowSize() {
	const [size, setSize] = useState([0, 0]);
	useLayoutEffect(() => {
		function updateSize() {
			setSize([window.innerWidth, window.innerHeight]);
		}
		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);
	return size;
}
