import React from "react";

import { Circles } from "react-loader-spinner";

const LoadingIndicator = ({ text1, text2, className }) => {
	return (
		<div className={className}>
			<div className="loadicon">
				<Circles color="#e82754" height={30} width={30} />
			</div>
			{text1 != "" && <div className="loadtext">{text1}</div>}
			{text2 != "" && <div className="loadtext">{text2}</div>}
		</div>
	);
};
export default LoadingIndicator;
