import React, { useState, useEffect } from "react";
import { UserService } from "../Services/UserService";
import AccountsAndLinks from "./accountsAndLinks";
import Hello from "./hello";
import SignIn from "./signIn";
import eventBus from "../classes/EventBus";
import { Events } from "../classes/AppEnum";

const AccountLink = ({ onSignIn, onLogoutClick, onAccountLinksDisplay }) => {
	const [showBox, setShowBox] = useState(null);
	const [isSignedIn, setIsSignedIn] = useState(null);
	const [contactName, setContactName] = useState(null);

	useEffect(() => {
		eventBus.on(Events.SigninBoxShouldBeShown, () => {
			setShowBox(true);
		});
		handleSignin();

		return function cleanup() {
			eventBus.remove(Events.SigninBoxShouldBeShown);
		};
	}, []);

	const handleSignin = () => {
		if (UserService.isSignedIn()) {
			setContactName(UserService.contactName());
			setIsSignedIn(true);
		} else {
			setIsSignedIn(false);
			setContactName("Sign In");
		}
		setShowBox(false);
	};
	return (
		<div className="account dropdown-tog">
			<Hello
				title={"Hello, " + contactName}
				onClick={() => {
					onAccountLinksDisplay(!showBox);
					setShowBox(!showBox);
				}}
			/>

			{showBox && isSignedIn && (
				<AccountsAndLinks
					onLogoutClick={() => {
						onLogoutClick();
						handleSignin();
					}}
				/>
			)}
			{showBox && !isSignedIn && (
				<SignIn
					signedIn={() => {
						handleSignin();
						onSignIn();
					}}
					onClickOnForgotPassword={() => {
						setShowBox(false);
					}}
					onClickOnRegister={() => {
						setShowBox(false);
					}}
				/>
			)}
		</div>
	);
};

export default AccountLink;
