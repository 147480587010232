import React, { useEffect } from "react";
import ThemeEight from "./theme-eight";
import ThemeSeven from "./theme-seven";
import ThemeOne from "./theme-one";

// import ThemeOne from "./theme-one";
// import ThemeSeven from "./theme-seven";
// import ThemeThree from "./theme-three";
// import ThemeFour from "./theme-four";
import ThemeTwo from "./theme-two";
import ThemeFive from "./theme-five";
// import ThemeSix from "./theme-six";

const Theme = ({ templateId, images }) => {
	useEffect(() => {}, [templateId, images]);
	return (
		<>
			{(() => {
				switch (templateId) {
					case "1":
						return <ThemeOne images={images} />;
					case "2":
						return <ThemeTwo images={images} />;

					// case "3":
					// 	return (
					// 		<ThemeThree images={images} onClickOnUpload={onClickOnUpload} />
					// 	);
					// case "4":
					// 	return (
					// 		<ThemeFour images={images} onClickOnUpload={onClickOnUpload} />
					// 	);

					case "5":
						return <ThemeFive images={images} />;
					// case "6":
					// 	return (
					// 		<ThemeSix images={images} onClickOnUpload={onClickOnUpload} />
					// 	);
					case "7":
						return <ThemeSeven images={images} />;
					case "8":
						return <ThemeEight images={images} />;
					default:
						return <p>Hello</p>;
				}
			})()}
		</>
	);
};
export default Theme;
