import React, { useState } from "react";
import axios from "axios";
import { UserService } from "../Services/UserService";
import LoadingIndicator from "./loadingIndicator";
import { Link } from "react-router-dom";

const SignIn = (props) => {
	const [email, setEmail] = useState("");
	const [accountNo, setAccountNo] = useState("");
	const [password, setPassword] = useState("");
	const [requestInProgress, setRequestInProgtess] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		setRequestInProgtess(true);
		UserService.signin(
			accountNo,
			email,
			password,
			(isSuccessfullySignedIn, responseMessage, error) => {
				setRequestInProgtess(false);
				if (error != null) {
					alert(error);
				} else {
					if (!isSuccessfullySignedIn) {
						alert(responseMessage);
					} else {
						props.signedIn();
					}
				}
			}
		);
	};
	return (
		<div className="dropdown">
			<div className="col signin pad-t">
				<h3 className="txt-md-c pad-tb">Sign In</h3>
				<form className="form1 body pad-lr" onSubmit={handleSubmit}>
					<div className="pad-tb">
						<input
							className="input stfocus"
							placeholder="Customer ID"
							type="text"
							value={accountNo}
							onChange={(e) => {
								setAccountNo(e.target.value);
							}}
						/>
					</div>
					<div className="pad-tb">
						<input
							className="input stfocus"
							placeholder="Email"
							type="email"
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
						/>
					</div>
					<div className="pad-tb">
						<input
							className="input stfocus"
							placeholder="Password"
							type="password"
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
						/>
					</div>
					<div>
						<a
							className="forgotpass uline"
							href="#"
							onClick={props.onClickOnForgotPassword}
						>
							Forgot Password
						</a>
					</div>
					<div className="pad-tb">
						{requestInProgress ? (
							<div style={{ textAlign: "center" }}>
								<LoadingIndicator />
							</div>
						) : (
							<input className="btn btn-full" type="submit" value="Submit" />
						)}
					</div>
				</form>

				<div className="footer pad-tb">
					<span className="uline">New To United?&nbsp;</span>
					<Link
						className="uline"
						to="/register"
						onClick={props.onClickOnRegister}
					>
						Click Here To Register
					</Link>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
