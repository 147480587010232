import React from "react";

import xImage from "../assets/x.svg";
import SingleProduct from "./Single-Product";

const SearchedProducts = ({ products, onClose }) => {
	return (
		<div className="col-r prod-suggestion">
			<h5 className="pad5 bdr-b">
				<span>Product Suggestions</span>
				<img className="x" src={xImage} onClick={onClose} />
				<div className="eor"></div>
			</h5>
			{products.map((product) => {
				return (
					<SingleProduct
						key={product.id}
						product={product}
						listedInCart={false}
						listedInSearchResults={true}
					/>
				);
			})}
		</div>
	);
};
export default SearchedProducts;
