import React, { useEffect, useState } from "react";

const Checkbox = ({ title, isChecked, onCheck, onUnCheck }) => {
	const [checked, setChecked] = useState(false);
	const handleChange = () => {
		checked ? onUnCheck() : onCheck();
		setChecked(!isChecked);
	};
	useEffect(() => {
		setChecked(isChecked);
	}, [isChecked]);
	return (
		<div className="pad5 filterOption">
			<input
				type="checkbox"
				onChange={handleChange}
				checked={checked}
				// defaultChecked={isChecked}
			/>
			{title}
		</div>
	);
};

export default Checkbox;
