import React, { useState, useEffect } from "react";

import { SupplierService } from "../Services/SupplierService";
import { Link } from "react-router-dom";

const HorizontalSuppliersList = ({ onSelect }) => {
	const { innerWidth: width, innerHeight: height } = window;

	const [suppliers, setSuppliers] = useState([]);

	const supplierWidth = 130;
	const [left, setLeft] = useState(0);
	// const suppliersListWidth =
	// 	suppliers.length == 0 ? supplierWidth : suppliers.length * supplierWidth;

	const getSuppliers = () => {
		SupplierService.getSuppliers("", (activeSuppliers) => {
			activeSuppliers.map((supp) => {});
			setSuppliers(activeSuppliers);
		});
	};
	const handleClickOnPrevious = () => {
		if (left <= -supplierWidth) {
			setLeft(left + supplierWidth);
		}
	};
	const handleClickOnNext = () => {
		// const totalLeftPossible = suppliersListWidth - width;
		// if (-left < totalLeftPossible) {
		// 	setLeft(left - supplierWidth);
		// }
	};

	useEffect(() => {
		getSuppliers();
	}, []);
	return (
		<section id="browse-supplier-shop">
			<h1 className="pad20-b">Browse Supplier Shops</h1>
			<article>
				{suppliers.map((supplier) => {
					return (
						<Link
							key={supplier.supplierCode}
							className="supplier"
							to={"/supplierShop/" + supplier.supplierCode}
							state={{ supplier: { supplier } }}
							onClick={(e) => {
								onSelect(supplier);
							}}
						>
							<img src={supplier.logo} />
						</Link>
					);
				})}
				<div className="eor"></div>
			</article>
		</section>
	);
};

export default HorizontalSuppliersList;
