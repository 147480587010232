import React, { useState } from "react";
import { useEffect } from "react";

const TimeSlotAndPaymentMethod = ({
	amount,
	timeSlotsForClickAndCollect,
	paymentMethods,
	onClickOnCheckout,
}) => {
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [selectedSlot, setSelectedSlot] = useState(null);

	useEffect(() => {
		setSelectedPaymentMethod(paymentMethods[0]);
		const date =
			timeSlotsForClickAndCollect.length > 0
				? timeSlotsForClickAndCollect[0]
				: null;
		setSelectedDate(date);
		setSelectedSlot(date ? date.timeSlots[0] : null);
	}, [paymentMethods, timeSlotsForClickAndCollect]);
	return (
		<div className="form1 txt-md-l">
			<div className="pad-tb field">
				<div className="lab">Payment Method :</div>
				<div className="val">
					<select
						className="select width-full"
						onChange={(e) => {
							setSelectedPaymentMethod(paymentMethods[e.target.selectedIndex]);
						}}
					>
						{paymentMethods.map((paymentMethod) => {
							return (
								<option key={paymentMethod.id}>
									{paymentMethod.paymentMethodName}
								</option>
							);
						})}
					</select>
				</div>
			</div>
			{timeSlotsForClickAndCollect.length > 0 && (
				<div>
					<div className="pad-tb field">
						<div className="lab">Prefered Day :</div>
						<div className="val">
							<select
								className="select width-full"
								onChange={(e) => {
									setSelectedDate(
										timeSlotsForClickAndCollect[e.target.selectedIndex]
									);
									setSelectedSlot(
										timeSlotsForClickAndCollect[e.target.selectedIndex]
											.timeSlots[0]
									);
								}}
							>
								{timeSlotsForClickAndCollect.map((day) => {
									return (
										<option key={day.date}>
											{day.dayName + " " + day.date}
										</option>
									);
								})}
							</select>
						</div>
					</div>
					{selectedDate && (
						<div className="pad-tb field">
							<div className="lab">Time Slot :</div>
							<div className="val">
								<select
									className="select width-full"
									value={selectedSlot}
									onChange={(e) => {
										setSelectedSlot(
											selectedDate.timeSlots[e.target.selectedIndex]
										);
									}}
								>
									{selectedDate.timeSlots.map((timeSlot) => {
										return <option key={timeSlot}>{timeSlot}</option>;
									})}
								</select>
							</div>
						</div>
					)}
				</div>
			)}

			<div className="pad-tb">
				<h3 className="txt-md-c">Your Payment £{amount}</h3>
			</div>
			<div className="pad-tb txt-md-c">
				<input
					className="btn btn-full"
					type="submit"
					value="Secure Checkout"
					onClick={() => {
						onClickOnCheckout(
							selectedPaymentMethod,
							selectedDate,
							selectedSlot
						);
					}}
				/>
			</div>
		</div>
	);
};
export default TimeSlotAndPaymentMethod;
