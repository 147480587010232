import gatesheadBanner from "../assets/gateshead-depot.png";
import springburnBanner from "../assets/springburn-depot.png";
import polmadieBanner from "../assets/polmadie-depot.png";

import singleImageInImagesContainer from "../assets/data/prod-list-banner.png";

export const containerImages = [
	{ id: 1, image: singleImageInImagesContainer },
	{ id: 2, image: singleImageInImagesContainer },
	{ id: 3, image: singleImageInImagesContainer },
	{ id: 4, image: singleImageInImagesContainer },
];
export const stores = [
	{
		id: 1,
		name: "Springburn",
		banner: springburnBanner,

		contact_info: {
			phone: "0141 557 2255",
			address: "246 Flemington Street, Springburn, Glasgow G21 4BY",
			email: "united123321@gmail.com",
		},
		timings: {
			mon_fri: "6am - 5.30pm",
			saturday: "6am - 5.30pm",
			sunday: "Closed",
		},
	},
	{
		id: 2,
		name: "Polmadie",
		banner: polmadieBanner,

		contact_info: {
			phone: "0141 423 1313",
			address: "350 Polmadie Road, Polmadie, Glasgow G42 0PH",
			email: "united123321@gmail.com",
		},
		timings: {
			mon_fri: "5am - 8pm",
			saturday: "5am - 8pm",
			sunday: "10am-7pm",
		},
	},
	{
		id: 3,
		name: "Gateshead",
		banner: gatesheadBanner,

		contact_info: {
			phone: "0191 482 5542",
			address: "Earlsway Team Valley Trading Estate, Gateshead, NE11 0RQ",
			email: "united123321@gmail.com",
		},
		timings: {
			mon_fri: "6am - 6pm",
			saturday: "6am - 6pm",
			sunday: "Closed",
		},
	},
];

export const CategoriesFocus = [
	{
		id: 1,
		name: "Impluse",
		imageURL:
			"https://www.uwglorder.co.uk/category_focus/Impulse_Category22.jpg",
		fileURL:
			"https://www.uwglorder.co.uk/category_focus/Impulse_Category22.pdf",
	},
	{
		id: 2,
		name: "Chill & Frozen",
		imageURL:
			"https://www.uwglorder.co.uk/category_focus/ChilledFrozen_Category22.jpg",
		fileURL:
			"https://www.uwglorder.co.uk/category_focus/ChilledFrozen_Category22.pdf",
	},
	{
		id: 3,
		name: "Tobacco",
		imageURL:
			"https://www.uwglorder.co.uk/category_focus/Tobacco_Category22.jpg",
		fileURL:
			"https://www.uwglorder.co.uk/category_focus/Tobacco_Category22.pdf",
	},
];

export const Brochures = [
	{
		id: 1,
		name: "Promotions",
		imageURL:
			"https://uwgl.co.uk/template/images/brochure/thumb/thumb_1661781869.jpg",
		fileURL:
			"https://uwgl.co.uk/template/images/brochure/file/uwglBrochure_1661781869.pdf",
	},
];
