import React, { useState, useEffect, useRef } from "react";
import x2 from "../assets/x2.svg";
import cart2 from "../assets/cart2.png";
import clearcart from "../assets/clearcart.svg";
import eventBus from "../classes/EventBus";
import { ProductService } from "../Services/ProductService";
import CartRequirements from "./cartRequirements";
import { Events } from "../classes/AppEnum";
import SingleProduct from "./Single-Product";
import { AppConstants } from "../misc/Constants";
import LoadingIndicator from "./loadingIndicator";
import { CartService } from "../Services/CartService";
import { useNavigate } from "react-router-dom";

const Cart = ({ style, onClose, onCheckout }) => {
	//State and Ref
	const [products, _setProducts] = useState([]);
	const [cartRequirements, _setCartRequirements] = useState(null);
	const [isClearCartViewHidden, setIsClearCartViewHidden] = useState(true);
	const [numberOfNonPromotionalCases, _setNumberOfNonPromotionalCases] =
		useState(0);
	const [numberOfTotalCases, _setNumberOfTotalCases] = useState(0);
	const [savedAmountOnCart, _setSaveAmountOnCart] = useState(0);
	const [
		isRequirementOfTotalCartFulfilled,
		_setisRequirementOfTotalCartFulfilled,
	] = useState(false);

	const [
		isRequirementOfNonPromotionalCartFulfilled,
		_setisRequirementOfNonPromotionalCartFulfilled,
	] = useState(false);

	const [
		amountOfNonPromotionalCartExcludingVAT,
		_setAmountOfNonPromotionalCartExcludingVAT,
	] = useState(0);

	const [amountOfTotalCartExcludingVAT, _setAmountOfTotalCartExcludingVAT] =
		useState(0);

	const [vatOfTotalCart, _setVATOfTotalCart] = useState(0);

	const [isLoading, _setIsLoading] = useState(true);
	const productsStateRef = useRef(products);
	const clearCartConfirmationTextfieldRef = useRef(null);
	const cartRequirementsStateRef = useRef(cartRequirements);

	const navigate = useNavigate();

	const numberOfNonPromotionalCasesStateRef = useRef(
		numberOfNonPromotionalCases
	);
	const numberOfTotalCasesStateRef = useRef(numberOfTotalCases);
	const amountOfNonPromotionalCartExcludingVATStateRef = useRef(
		amountOfNonPromotionalCartExcludingVAT
	);
	const amountOfTotalCartExcludingVATStateRef = useRef(
		amountOfTotalCartExcludingVAT
	);

	const vatOfTotalCartStateRef = useRef(vatOfTotalCart);

	const setProducts = (updatedProducts) => {
		productsStateRef.current = updatedProducts;
		_setProducts(updatedProducts);
		setCartCasesAndAmount(updatedProducts);
	};
	const setCartRequirements = (updatedCartRequirements) => {
		cartRequirementsStateRef.current = updatedCartRequirements;
		console.log("Cart Req: " + updatedCartRequirements);
		_setCartRequirements(updatedCartRequirements);
	};

	const setCartCasesAndAmount = (updatedProducts) => {
		let nonPromotionalCases = 0;
		let totalCases = 0;
		let nonPromotionalAmount = 0;
		let totalAmount = 0.0;
		let vat = 0.0;
		let savedAmount = 0.0;
		for (let index in updatedProducts) {
			savedAmount +=
				(parseFloat(updatedProducts[index].stdPrice) -
					parseFloat(updatedProducts[index].price)) *
				parseInt(updatedProducts[index].qty);
			if (updatedProducts[index].packsize > 1) {
				if (
					ProductService.isProductNonPromotionalForCart(updatedProducts[index])
				) {
					nonPromotionalCases =
						nonPromotionalCases + parseInt(updatedProducts[index].qty);
				}

				totalCases = totalCases + parseInt(updatedProducts[index].qty);
			}

			if (
				ProductService.isProductNonPromotionalForCart(updatedProducts[index])
			) {
				nonPromotionalAmount =
					nonPromotionalAmount +
					parseInt(updatedProducts[index].qty) *
						parseFloat(updatedProducts[index].price);
			}
			totalAmount =
				totalAmount +
				parseInt(updatedProducts[index].qty) *
					parseFloat(updatedProducts[index].price);
			vat =
				vat +
				parseInt(updatedProducts[index].qty) *
					parseFloat(updatedProducts[index].vatValue);
		}

		numberOfNonPromotionalCasesStateRef.current = nonPromotionalCases;
		numberOfTotalCasesStateRef.current = totalCases;
		amountOfNonPromotionalCartExcludingVATStateRef.current =
			nonPromotionalAmount;
		amountOfTotalCartExcludingVATStateRef.current = totalAmount;
		vatOfTotalCartStateRef.current = vat;

		_setSaveAmountOnCart(savedAmount);
		_setAmountOfNonPromotionalCartExcludingVAT(nonPromotionalAmount.toFixed(2));
		_setAmountOfTotalCartExcludingVAT(totalAmount.toFixed(2));
		_setNumberOfNonPromotionalCases(nonPromotionalCases);
		_setNumberOfTotalCases(totalCases);
		_setVATOfTotalCart(vat.toFixed(2));
	};

	const getCart = () => {
		_setIsLoading(true);
		ProductService.getCart((cartProducts, cartRequirements, error) => {
			_setIsLoading(false);
			if (error != null) {
				alert(error);
				return;
			}
			setProducts(cartProducts);
			setCartRequirements(cartRequirements);
		});
	};
	const removeProduct = (productToRemove) => {
		let updatedProducts = productsStateRef.current;
		updatedProducts = updatedProducts.filter((product) => {
			return product.id != productToRemove.id;
		});
		setProducts(updatedProducts);
	};
	const addOrUpdateProduct = (newProduct) => {
		let updatedProducts = productsStateRef.current;
		let isProductAlreadyInCart = false;

		updatedProducts = updatedProducts.map((product) => {
			if (product.id == newProduct.id) {
				product["qty"] = newProduct.qty;
				isProductAlreadyInCart = true;
			}
			return product;
		});
		if (isProductAlreadyInCart) {
			setProducts([]);
			setProducts(updatedProducts);
		} else {
			setProducts([newProduct, ...productsStateRef.current]);
		}
	};
	const handleSubmit = () => {
		if (
			isRequirementOfNonPromotionalCartFulfilled &&
			isRequirementOfTotalCartFulfilled
		) {
			const amount = parseFloat(
				parseFloat(vatOfTotalCart) + parseFloat(amountOfTotalCartExcludingVAT)
			).toFixed(2);
			onClose();
			navigate("/checkout", { state: amount });

			// onCheckout(amount);
		}
	};
	const handleClearCartRequest = () => {
		if (
			clearCartConfirmationTextfieldRef.current &&
			clearCartConfirmationTextfieldRef.current.value == "delete"
		) {
			CartService.clearCart((error) => {
				if (error) {
					alert(error);
				} else {
					eventBus.dispatch(Events.CartDeleted, {});
					setIsClearCartViewHidden(true);
					getCart();
				}
			});
		}
	};
	useEffect(() => {
		eventBus.on(Events.productQuantityChangedInCart, (data) => {
			if (data.product.qty > 0) {
				addOrUpdateProduct(data.product);
			} else {
				removeProduct(data.product);
			}
		});

		eventBus.on(Events.UserPreferencesChanged, () => {
			getCart();
		});
		getCart();

		return function cleanup() {
			eventBus.remove(Events.productQuantityChangedInCart);
			eventBus.remove(Events.UserPreferencesChanged);
		};
	}, []);

	return (
		<section style={style} id="cart-pane">
			<article className="cart-pane-header">
				<div className="col-xs-8" onClick={onClose}>
					<div className="pad5-lr">
						<a className="x">
							<img width="14px" height="14px" src={x2} />
						</a>
					</div>
				</div>
				<div className="col-xs-2 txt-md-c">
					<div className="pad5-lr">
						<img
							width={16}
							src={clearcart}
							onClick={() => {
								clearCartConfirmationTextfieldRef.current.value = "";
								setIsClearCartViewHidden(!isClearCartViewHidden);
							}}
						/>
					</div>
				</div>
				<div className="col-xs-2 txt-md-r">
					<div className="pad5-lr">
						{numberOfTotalCases} <img src={cart2} />
					</div>
				</div>
				<div className="eor"></div>
			</article>
			<article className="pad-tb bdr-b form1" hidden={isClearCartViewHidden}>
				<h4 className="txt-md-c midbold txtred pad-b">
					Do you really want to clear your trolley?
				</h4>
				<div className="txt-md-c pad-b">
					All the items added to trolley will be deleted. To clear your trolley
					type "<span className="txtblack norbold">delete</span>" below and
					click "<span className="txtblack norbold">Clear Trolley</span>"
					button.
				</div>
				<div className="form2">
					<input
						type="text"
						className="input"
						ref={clearCartConfirmationTextfieldRef}
						placeholder="delete"
					/>
					<input
						className="input"
						type="submit"
						onClick={handleClearCartRequest}
						value={"Clear Trolley"}
					/>
				</div>
			</article>
			{isLoading ? (
				<div
					style={{
						textAlign: "center",
						height: "70px",
						paddingTop: "30px",
					}}
				>
					<LoadingIndicator />{" "}
				</div>
			) : (
				<>
					{cartRequirements != null && (
						<CartRequirements
							cartRequirements={cartRequirements}
							numberOfNonPormotionalCases={numberOfNonPromotionalCases}
							numberOfTotalCases={numberOfTotalCases}
							nonPromotionalCartAmount={amountOfNonPromotionalCartExcludingVAT}
							totalCartAmount={amountOfTotalCartExcludingVAT}
							isRequirementOfTotalFulfilled={(fulfilled) => {
								_setisRequirementOfTotalCartFulfilled(fulfilled);
							}}
							isRequirementOfNonPromotionalFulfilled={(fulfilled) => {
								_setisRequirementOfNonPromotionalCartFulfilled(fulfilled);
							}}
						/>
					)}
					<article className="bdr-b spendsave">
						{products.map((product) => {
							return (
								<SingleProduct
									key={product.id}
									product={product}
									listedInCart={true}
								/>
							);
						})}
					</article>
					{savedAmountOnCart > 0 && (
						<article className="pad bdr-b">
							<div>
								<div className="col-md-8 txt-md-l">Saving on this order</div>
								<div className="col-md-4 bold-md txt-md-r">
									£{savedAmountOnCart.toFixed(2)}
								</div>
								<div className="eor"></div>
							</div>
						</article>
					)}
					<article className="pad bdr-b">
						<div>
							<div className="col-md-8 txt-md-l">Subtotal</div>
							<div className="col-md-4 bold-md txt-md-r">
								{AppConstants.POUND_SIGN + amountOfTotalCartExcludingVAT}
							</div>
							<div className="eor"></div>
						</div>
						<div>
							<div className="col-md-8 txt-md-l">Delivery</div>
							<div className="col-md-4 bold-md txt-md-r">-</div>
							<div className="eor"></div>
						</div>
						<div>
							<div className="col-md-8 txt-md-l">VAT</div>
							<div className="col-md-4 bold-md txt-md-r">
								{AppConstants.POUND_SIGN + vatOfTotalCart}
							</div>
							<div className="eor"></div>
						</div>
						<div>
							<div className="col-md-8 txt-md-l">Total</div>
							<div className="col-md-4 bold-md txt-md-r">
								{AppConstants.POUND_SIGN +
									parseFloat(
										parseFloat(vatOfTotalCart) +
											parseFloat(amountOfTotalCartExcludingVAT)
									).toFixed(2)}
							</div>
							<div className="eor"></div>
						</div>
					</article>
					<article className="pad">
						<input
							type="button"
							className="btn btn1"
							value="Review Trolley &amp; Checkout"
							onClick={handleSubmit}
						/>
					</article>
				</>
			)}
		</section>
	);
};

export default Cart;
