import React from "react";

import previousArrowImage from "../assets/prv-slide.png";

const PreviousArrow = ({ onClickHandler, marginTop }) => {
	return (
		<img
			className="prev"
			style={{ marginTop: marginTop }}
			onClick={onClickHandler}
			src={previousArrowImage}
		/>
	);
};

export default PreviousArrow;
