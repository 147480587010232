import React from "react";

const Footer = () => {
	return (
		<footer>
			<div className="container">
				{/* <!-- Links --> */}
				<div className="col-sm-6 txt-md-l">
					<a
						target="_blank"
						href="https://www.uwglorder.co.uk/pages/terms_conditions.php"
					>
						Terms of Use
					</a>
					<span>|</span>
					<a
						target="_blank"
						href="https://www.uwglorder.co.uk/pages/privacy_policy.php"
					>
						Privacy Policy
					</a>
					<span>|</span>
					<a target="_blank" href="https://www.uwglorder.co.uk/pages/eula.php">
						End User License Agreement
					</a>
				</div>
				{/* <!-- /Links --> */}

				{/* <!-- Copyrights --> */}
				<div className="col-sm-6 copyright">
					www.uwglorder.co.uk © 2022. All Rights Reserved.
				</div>
				{/* <!-- /Copyrights --> */}

				<div className="eor"></div>
			</div>
		</footer>
	);
};

export default Footer;
