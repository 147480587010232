import React from "react";
const B4 = ({ imageUrl, onClick }) => {
	return (
		<div className="" onClick={onClick}>
			<a>
				<img width="100%" src={imageUrl} />
			</a>
		</div>
	);
};

export default B4;
