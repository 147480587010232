import React from "react";

import { CartRequirementsType } from "../classes/AppEnum";
import { AppConstants } from "../misc/Constants";
import CartInstruction from "./cartInstruction";

const CartRequirements = ({
	cartRequirements,
	numberOfNonPormotionalCases,
	numberOfTotalCases,
	nonPromotionalCartAmount,
	totalCartAmount,
	isRequirementOfTotalFulfilled,
	isRequirementOfNonPromotionalFulfilled,
}) => {
	const numberOfNonPormotionalCasesRequired = () => {
		let requiredNumber =
			cartRequirements.non_promotional_items - numberOfNonPormotionalCases;

		if (cartRequirements.flag != CartRequirementsType.fixedNumberOfCases) {
			if (requiredNumber < 0) {
				requiredNumber = 0;
			}
		}
		if (requiredNumber == 0) {
			isRequirementOfNonPromotionalFulfilled(true);
		} else {
			isRequirementOfNonPromotionalFulfilled(false);
		}

		return requiredNumber;
	};

	const numberOfTotalCasesRequired = () => {
		let requiredNumber = 0;

		if (cartRequirements.flag == CartRequirementsType.minimumNumberOfCases) {
			requiredNumber = cartRequirements.total_items - numberOfTotalCases;

			if (requiredNumber < 0) {
				requiredNumber = 0;
			}
		} else {
			isRequirementOfNonPromotionalFulfilled(true);
			requiredNumber =
				cartRequirements.total_items_fixed_quantity - numberOfTotalCases;
		}

		if (requiredNumber == 0) {
			isRequirementOfTotalFulfilled(true);
		} else {
			isRequirementOfTotalFulfilled(false);
		}
		return requiredNumber;
	};

	const initialText = () => {
		if (cartRequirements.flag == CartRequirementsType.fixedNumberOfCases) {
			return "";
		}
		return "Min";
	};
	const boldTextForNonPromotional = () => {
		if (cartRequirements.flag == CartRequirementsType.minimumAmount) {
			let requiredAmount = parseFloat(
				cartRequirements.non_promotional_vat - nonPromotionalCartAmount
			).toFixed(2);
			requiredAmount = requiredAmount > 0 ? requiredAmount : 0;
			if (requiredAmount == 0) {
				isRequirementOfNonPromotionalFulfilled(true);
				return 0;
			} else {
				isRequirementOfNonPromotionalFulfilled(false);
			}
			return AppConstants.POUND_SIGN + requiredAmount;
		}
		return numberOfNonPormotionalCasesRequired();
	};

	const boldTextForTotal = () => {
		if (cartRequirements.flag == CartRequirementsType.minimumAmount) {
			let requiredAmount = parseFloat(
				cartRequirements.total_items_vat - totalCartAmount
			).toFixed(2);

			requiredAmount = requiredAmount > 0 ? requiredAmount : 0;
			if (requiredAmount == 0) {
				isRequirementOfTotalFulfilled(true);
				return 0;
			} else {
				isRequirementOfTotalFulfilled(false);
			}
			return AppConstants.POUND_SIGN + requiredAmount;
		}
		return numberOfTotalCasesRequired();
	};

	const finalTextForNonPromotional = () => {
		if (cartRequirements.flag == CartRequirementsType.minimumAmount) {
			return "On Non-Promotional Items";
		}
		return "Non-Promotional Cases";
	};
	const finalTextForTotal = () => {
		if (cartRequirements.flag == CartRequirementsType.minimumAmount) {
			return "On Total Cases";
		}
		return "Total Cases";
	};

	return (
		<>
			{(cartRequirements.flag == CartRequirementsType.fixedNumberOfCases &&
				boldTextForTotal() == 0) ||
				(boldTextForTotal() == 0 && boldTextForNonPromotional() == 0) || (
					<article className="pad bdr-b">
						{cartRequirements.flag == CartRequirementsType.fixedNumberOfCases ||
							boldTextForNonPromotional() == 0 || (
								<CartInstruction
									initialText={initialText()}
									boldText={boldTextForNonPromotional()}
									finalText={finalTextForNonPromotional()}
								/>
							)}
						{boldTextForTotal() == 0 || (
							<CartInstruction
								initialText={initialText()}
								boldText={boldTextForTotal()}
								finalText={finalTextForTotal()}
							/>
						)}
						{/* <br />
			required to checkout */}
					</article>
				)}
		</>
	);
};

export default CartRequirements;
