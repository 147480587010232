import { Link } from "react-router-dom";

const Order = ({ order, key }) => {
	return (
		<tr key={key}>
			<td className="txt-md-l">{order.Date_Order}</td>
			<td className="txt-md-l">
				{order.Order_No +
					(order.Customer_Ref == "" ? "" : "-" + order.Customer_Ref)}
			</td>
			<td className="txt-md-l">{order.Date_Invoiced}</td>
			<td className="txt-md-r">
				{order.Invoice_no == "" || order.Invoice_no == null ? (
					<>{order.Order_Status}</>
				) : (
					<>
						<Link
							to={"/orderDetails/" + order.Order_No + "/" + order.Customer_Ref}
						>
							{order.Invoice_no}
						</Link>
					</>
				)}
			</td>
			<td className="txt-md-r">{order.Case_Count}</td>
			{/* <td className="txt-md-r">{"0"}</td> */}
			<td className="txt-md-r">
				{"£" + parseFloat(order.VAT_Z_Order_Value).toFixed(2)}
			</td>
			<td className="txt-md-r">
				{"£" + parseFloat(order.VAT_C_Order_Value).toFixed(2)}
			</td>
			<td className="txt-md-r">
				{"£" + parseFloat(order.VAT_C_Value).toFixed(2)}
			</td>
			<td className="txt-md-r">
				{"£" + parseFloat(order.VAT_A_Order_Value).toFixed(2)}
			</td>
			<td className="txt-md-r">
				{"£" + parseFloat(order.VAT_A_Value).toFixed(2)}
			</td>
			<td className="txt-md-r">
				{"£" + parseFloat(order.Total_Goods_Value).toFixed(2)}
			</td>
			<td className="txt-md-r">
				{"£" + parseFloat(order.Total_VAT_Value).toFixed(2)}
			</td>
			<td className="txt-md-r">
				{"£" + parseFloat(order.Total_Order_Value).toFixed(2)}
			</td>
		</tr>
	);
};

export default Order;
