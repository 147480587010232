import React, { useState, useEffect } from "react";

import Booklet from "./Booklet";
import HorizontalProductsGrid from "./HorizontalProductsGrid";
import { ExternalFileType, SpecialFilter } from "../classes/AppEnum";
import { UserService } from "../Services/UserService";
import LoadingIndicator from "./loadingIndicator";
const ExternalFiles = ({ topText, files, bottomText, type }) => {
	const [brochureImageUrl, setBrochureImageUrl] = useState(null);
	const [brochureFileUrl, setBrochureFileUrl] = useState(null);

	const getBrouchure = () => {
		UserService.getBrochure((imageUrl, fileUrl, error) => {
			if (error) {
				getBrouchure();
			} else {
				setBrochureImageUrl(imageUrl);
				setBrochureFileUrl(fileUrl);
			}
		});
	};
	useEffect(() => {
		getBrouchure();
	}, []);
	return (
		<section id="categoryfocus">
			{topText.length > 0 && (
				<h3 className="pad40-t txt-md-c light">{topText}</h3>
			)}
			{type == ExternalFileType.BROCHURE ? (
				brochureImageUrl == null ? (
					<LoadingIndicator />
				) : (
					<Booklet
						imageURL={brochureImageUrl}
						fileURL={brochureFileUrl}
						className={"txt-md-c pad40-t"}
					/>
				)
			) : (
				files.map((file, index) => {
					return (
						<Booklet
							imageURL={file.imageURL}
							fileURL={file.fileURL}
							className={
								index == 0
									? "col-md-4 col-sm-12 txt-xs-c txt-sm-c txt-md-r pad40-tb"
									: index == 1
									? "col-md-4 col-sm-6 txt-xs-c txt-md-c pad40-tb"
									: "col-md-4 col-sm-6 txt-xs-c txt-md-l pad40-tb"
							}
						/>
					);
				})
			)}
			{bottomText.length > 0 && (
				<h3 className="pad40-t txt-md-c light">{bottomText}</h3>
			)}

			<HorizontalProductsGrid SpecialFilters={[SpecialFilter.PROMOTIONS]} />
		</section>
	);
};

export default ExternalFiles;
