import React, { useState, useRef } from "react";
import { UserService } from "../Services/UserService";
import LoadingIndicator from "./loadingIndicator";

const ChangePassword = ({ onClickOnCancel }) => {
	const currentPasswordFieldRef = useRef(null);
	const newPasswordFieldRef = useRef(null);
	const confirmPasswordFieldRef = useRef(null);
	const [showLoadingIndicator, setShowLoadingIndiator] = useState(false);
	const [error, setError] = useState(null);

	const handleTextFieldOnChange = () => {
		setError(null);
	};
	const handleSubmit = () => {
		if (
			currentPasswordFieldRef.current.value &&
			newPasswordFieldRef.current.value &&
			confirmPasswordFieldRef.current.value
		) {
			if (
				newPasswordFieldRef.current.value ==
				confirmPasswordFieldRef.current.value
			) {
				postPasswordChanges(
					currentPasswordFieldRef.current.value,
					newPasswordFieldRef.current.value
				);
			} else {
				setError("New password and cofirm password do not match");
			}
		}
	};
	const postPasswordChanges = (currentPassword, newPassword) => {
		setError(null);
		setShowLoadingIndiator(true);
		UserService.resetPassword(currentPassword, newPassword, (error) => {
			setShowLoadingIndiator(false);
			if (error) {
				setError(String(error));
			} else {
				currentPasswordFieldRef.current.value = "";
				newPasswordFieldRef.current.value = "";
				confirmPasswordFieldRef.current.value = "";
				alert("Password updated Successfully.");
			}
		});
	};
	return (
		<div className="pad-b">
			<div className="txt-md-c box500">
				<h1 className="line40">Change Password</h1>
				<div className="pad-tb line18">
					To change your password you need to fill in the three boxes below, The
					new password you enter will then be used with your e-mail address next
					time you login to the website.
				</div>
				<form
					onSubmit={(e) => {
						e.preventDefault();
						handleSubmit();
					}}
				>
					<div className="form1 txt-md-l form-width1">
						<div className="pad-tb field">
							<div className="lab">Current Password:</div>
							<div className="val">
								<input
									className="input jsfocus"
									type="password"
									ref={currentPasswordFieldRef}
									onChange={handleTextFieldOnChange}
								/>
							</div>
						</div>
						<div className="pad-tb field">
							<div className="lab">New Password:</div>
							<div className="val">
								<input
									className="input jsfocus"
									type="password"
									ref={newPasswordFieldRef}
									onChange={handleTextFieldOnChange}
								/>
							</div>
						</div>
						<div className="pad-tb field">
							<div className="lab">Confirm Password:</div>
							<div className="val">
								<input
									className="input jsfocus"
									type="password"
									ref={confirmPasswordFieldRef}
									onChange={handleTextFieldOnChange}
								/>
							</div>
							{error && <div style={{ color: "red" }}>{error}</div>}
						</div>
						{showLoadingIndicator ? (
							<LoadingIndicator />
						) : (
							<div className="pad-tb">
								<div className="col-md-6 txt-md-l">
									<input
										className="btn btn120 btn-white"
										type="button"
										value="CANCEL"
										onClick={onClickOnCancel}
									/>
								</div>
								<div className="col-md-6 txt-md-r">
									<input className="btn btn120" type="submit" value="UPDATE" />
								</div>
								<div className="eor"></div>
							</div>
						)}
					</div>
				</form>
			</div>
		</div>
	);
};

export default ChangePassword;
