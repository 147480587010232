import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ProductTypes } from "../classes/AppEnum";
import { UserService } from "../Services/UserService";

const ProductTypesList = () => {
	const [selectedProductTypeId, setSelectedProductTypeId] = useState(null);

	const getUserPreferences = () => {
		UserService.getPreferences(
			(orderTypeName, productTypeName, orderTypeId, productTypeId, error) => {
				setSelectedProductTypeId(productTypeId);
			}
		);
	};

	useEffect(() => {
		getUserPreferences();
	}, []);
	return (
		<div className="col-md-6">
			{ProductTypes.getAll().map((productType) => {
				return (
					<ProductType
						productType={productType}
						isSelected={productType.id == selectedProductTypeId}
						handleChangeInSelectedType={(productTypeId) => {
							setSelectedProductTypeId(productTypeId);
						}}
					/>
				);
			})}

			<div className="pad20-t pad20-l pad20-r">
				{/* <a className="prodtype-cancel" onClick={onCancel}>
					Cancel
				</a> */}
			</div>
		</div>
	);
};

export default ProductTypesList;

export const ProductType = ({
	productType,
	isSelected,
	handleChangeInSelectedType,
}) => {
	const handleClick = () => {
		UserService.setProductTypePreference(productType.id, (error) => {
			if (error != null) {
				alert(error);
				return;
			}
			handleChangeInSelectedType(productType.id);
		});
	};

	useEffect(() => {}, [isSelected]);
	return (
		<div className="pad20-t pad20-l pad20-r" key={productType.id}>
			<a
				className={
					"prod-type prod-type" + productType.id + (isSelected ? " on" : "")
				}
				href="#"
				onClick={handleClick}
			>
				{productType.name}
			</a>
		</div>
	);
};
