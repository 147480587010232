import React, { useState, useEffect } from "react";

import Header from "./header";
import ImageSlider from "./Image-Slider";
import BrochureAppLink from "./Brochure-App-Link";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { Brochures, CategoriesFocus } from "../misc/constantData";

import ProductsGrid from "./productsGrid";

import {
	ExternalFileType,
	ScrollDirections,
	SpecialFilter,
} from "../classes/AppEnum";
import Cart from "./cart";
import SelectOrderType from "./selectOrderType";
import BottomGrid from "./BottomGrid";
import JoinUs from "./JoinUs";
import Checkout from "./Checkout";
import HorizontalProductsGrid from "./HorizontalProductsGrid";
import ExternalFiles from "./ExternalFiles";
import { AppConstants } from "../misc/Constants";
import StoreInfo from "./StoreInfo";
import NewsLetter from "./NewsLetter";
import { UserService } from "../Services/UserService";
import Footer from "./Footer";
import AccountLinkDetails from "./AccountLinkDetails";
import { Utils } from "../Services/Utils";
import HorizontalSuppliersList from "./HorizontalSuppliersList";
import SupplierShop from "./SupplierShop";
import { SupplierService } from "../Services/SupplierService";
import OrderHistory from "./OrderHistory";
import PrivateRoute from "./private_route";
import SpecialFilterComponent from "./special_filter";
import { AccountLink } from "../classes/AppEnum";
import OrderDetail from "./order_detail";

const Home = () => {
	const [checkout, _setCheckout] = useState(false);
	const [checkoutAmount, _setCheckoutAmount] = useState(0.0);

	const [shouldDisplayCart, _setShouldDisplayCart] = useState(false);
	const [refreshCount, _setRefreshCount] = useState(0);
	const [shouldDisplayOrderSettings, _setshouldDisplayOrderSettings] =
		useState(false);
	const [orderTypeId, _setOrderTypeId] = useState(false);

	const [supplier, setSupplier] = useState(null);
	const [dimensions, setDimensions] = useState({
		height: window.innerHeight,
		width: window.innerWidth,
	});

	const [displayCategories, setDisplayCategories] = useState(false);

	const [scrollDirection, setScrollDirection] = useState(ScrollDirections.UP);

	const handleAccountLinksDisplay = (displayed) => {
		if (displayed) {
			_setShouldDisplayCart(false);
			_setshouldDisplayOrderSettings(false);
		}
	};
	const forceRefresh = () => {
		_setRefreshCount(refreshCount + 1);
	};
	const handleClickOnLogout = () => {
		UserService.logout();
		forceRefresh();
	};
	const handleSignin = () => {
		forceRefresh();
	};

	const handleSupplierSelect = (supplier) => {
		_setShouldDisplayCart(false);
		_setshouldDisplayOrderSettings(false);
		_setCheckout(false);

		setSupplier(supplier);

		forceRefresh();
	};
	const handleClickOnCart = () => {
		_setShouldDisplayCart(true);
		_setshouldDisplayOrderSettings(false);
		forceRefresh();
	};
	const handleClickOnOrderSettingChange = (newOrderTypeId) => {
		_setshouldDisplayOrderSettings(true);
		_setOrderTypeId(newOrderTypeId);
		_setShouldDisplayCart(false);
		forceRefresh();
	};
	useEffect(() => {
		function handleResize() {
			console.log("Resize");
			setDimensions({
				height: window.innerHeight,
				width: window.innerWidth,
			});
		}

		window.addEventListener("resize", handleResize);
		window.addEventListener("load", () => {
			// Code to be executed on page load
			console.log("Load");
			handleResize();
		});
		return () => {
			// window.removeEventListener("resize");
		};
	}, [refreshCount]);

	return (
		<Router>
			<div>
				<Header
					scrollDirection={scrollDirection}
					refreshCount={refreshCount}
					specialFilters={SpecialFilter.getAll()}
					onCartClick={handleClickOnCart}
					handleClickOnOrderSettingChange={handleClickOnOrderSettingChange}
					onLogoutClick={handleClickOnLogout}
					onAccountLinksDisplay={handleAccountLinksDisplay}
					displayCategories={displayCategories}
					handleSignin={handleSignin}
				/>
				{shouldDisplayCart && (
					<Cart
						style={{ zIndex: 1000, right: 0 }}
						onClose={() => {
							_setShouldDisplayCart(false);
						}}
					/>
				)}
				{shouldDisplayOrderSettings && (
					<SelectOrderType
						orderTypeId={orderTypeId}
						handleClickOnX={() => _setshouldDisplayOrderSettings(false)}
					/>
				)}

				<switch>
					<Routes>
						<Route
							path="/"
							element={
								<>
									<div>
										<ImageSlider />
										<HorizontalSuppliersList
											onSelect={(supplier) => {
												SupplierService.logVisitToSupplierShop(
													supplier.supplierCode,
													(error) => {}
												);
												handleSupplierSelect(supplier);
											}}
										/>
										<HorizontalProductsGrid
											SpecialFilters={[
												SpecialFilter.PROMOTIONS,
												SpecialFilter.OVER_FOURTY_PERCENT_POR,
												SpecialFilter.ONE_POUND_RANGE,
											]}
										/>
										<BrochureAppLink
											onClickOnBrochure={() => {
												// handleClickOnSpecialFilter(SpecialFilter.PROMOTIONS);
											}}
										/>
										{UserService.isSignedIn() || (
											<JoinUs onClickOnLogin={() => {}} />
										)}
										<BottomGrid
											onClickOnDirectToStore={() => {
												Utils.directToStore();
											}}
										/>
									</div>
									<Footer />
								</>
							}
						/>
						<Route
							path="/orderHistory"
							element={
								<PrivateRoute>
									<AccountLinkDetails accountLink={AccountLink.ORDER_HISTORY} />
								</PrivateRoute>
							}
						/>
						<Route
							path="/orderDetails/:orderNo/:customerCode?"
							element={
								<PrivateRoute>
									<OrderDetail />
								</PrivateRoute>
							}
						/>
						<Route
							path="/importOrder"
							element={
								<PrivateRoute>
									<AccountLinkDetails accountLink={AccountLink.IMPORT_ORDER} />
								</PrivateRoute>
							}
						/>
						<Route
							path="/myDetails"
							element={
								<PrivateRoute>
									<AccountLinkDetails accountLink={AccountLink.USER_DETAILS} />
								</PrivateRoute>
							}
						/>
						<Route
							path="/register"
							element={
								<AccountLinkDetails
									accountLink={AccountLink.REGISTER_EXISTING_CUSTOMER}
								/>
							}
						/>

						<Route
							path="/categoryFocus"
							element={
								<ExternalFiles
									topText={AppConstants.CategoryFocus_Top_Text}
									files={CategoriesFocus}
									bottomText={AppConstants.CategoryFocus_Bottom_Text}
									type={ExternalFileType.CATEGORY_FOCUS}
								/>
							}
						/>
						<Route
							path="/brochure"
							element={
								<PrivateRoute>
									<ExternalFiles
										topText={AppConstants.Brochures_Page_Top_Text}
										files={Brochures}
										bottomText={AppConstants.Brochure_Page_Bottom_Text}
										type={ExternalFileType.BROCHURE}
									/>
								</PrivateRoute>
							}
						/>
						<Route
							path="/store/:storeName"
							element={
								<>
									<StoreInfo />
									<NewsLetter />
								</>
							}
						/>
						<Route
							path="/products/specialFilters/:specialFilterName"
							element={<SpecialFilterComponent />}
						/>
						<Route
							path="/products/search/:searchText?"
							element={<ProductsGrid />}
						/>

						<Route
							path="/products/category/:categoryId/:subCategoryId?"
							element={<ProductsGrid />}
						/>
						<Route
							path="/products/codes/:productCodes"
							element={<ProductsGrid />}
						/>

						<Route
							path="/supplierShop/:supplierCode/:supplierShopImageKey?"
							element={<SupplierShop />}
						/>
						<Route path="/checkout" element={<Checkout />} />
					</Routes>
				</switch>
			</div>
		</Router>
	);
};

export default Home;