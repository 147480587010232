import React, { useState, useEffect, useRef } from "react";

import eventBus from "../classes/EventBus";
import { ProductService } from "../Services/ProductService";
import { Events } from "../classes/AppEnum";
import SingleProductForListing from "./SingleProductForListing";
import SingleCartProduct from "./singleCartProduct";
import SingleProductInSearchResults from "./SingleProductInSearchResults";

const SingleProduct = ({
	title = null,
	product,
	listedInCart,
	listedInSearchResults,
}) => {
	const [currentProduct, _setCurrentProduct] = useState(product);
	const [shouldShowSignInToBuy, setShouldShowSignInToBuy] = useState(false);

	const productRef = useRef(null);

	const setProduct = (updatedProduct) => {
		productRef.current = updatedProduct;
		//{...updatedProduct} is needed because otherwise react won't know if a change is made or its still the same. And it won't re-render.
		_setCurrentProduct({ ...updatedProduct });
	};
	const handleMouseOver = () => {
		setShouldShowSignInToBuy(true);
	};

	const handleMouseLeave = () => {
		setShouldShowSignInToBuy(false);
	};

	const handleClickonAdd = (quantity) => {
		changeProductQuantity(quantity);
	};
	const handleClickOnFavourite = () => {
		ProductService.changeFavouriteStatus(product.id, (error) => {
			if (error != null) {
				alert(error);
				return;
			}
			let upodatedProduct = product;
			upodatedProduct.isFavourite = product.isFavourite == "1" ? "0" : "1";
			setProduct(upodatedProduct);
		});
	};
	const handleClickOnRemove = () => {
		changeProductQuantity(0);
	};
	const changeProductQuantity = (quantity) => {
		ProductService.addToCart(productRef.current.id, quantity, (error) => {
			if (error != null) {
				alert(error);
				const oldQuantity = currentProduct.qty;
				productQuantityChanged(0, false);
				productQuantityChanged(oldQuantity, false);
			} else {
				productQuantityChanged(quantity, true);
			}
		});
	};
	const productQuantityChanged = (quantity, changedInCart) => {
		let updatedProduct = productRef.current;
		updatedProduct["qty"] = quantity;

		setProduct(updatedProduct);
		if (changedInCart) {
			eventBus.dispatch(Events.productQuantityChangedInCart, {
				product: productRef.current,
			});
		}
	};
	useEffect(() => {
		setProduct(product);
		eventBus.on(Events.productQuantityChangedInCart, (data) => {
			if (!listedInCart && data.product.id == product.id) {
				product["qty"] = data.product.qty;
				setProduct(product);
			}
		});
		eventBus.on(Events.CartDeleted, (data) => {
			if (product.qty > 0) {
				product["qty"] = 0;
				setProduct(product);
			}
		});

		return function cleanup() {
			eventBus.remove(Events.productQuantityChangedInCart);
			eventBus.remove(Events.UserPreferencesChanged);
			eventBus.remove(Events.CartDeleted);
		};

	}, [product]);
	return (
		<>
			{title != null && <h4 className="txt-md-c">{title}</h4>}
			{listedInCart ? (
				<SingleCartProduct
					product={currentProduct}
					handleClickonAdd={handleClickonAdd}
					handleClickOnRemove={handleClickOnRemove}
				/>
			) : (
				<>
					{listedInSearchResults ? (
						<SingleProductInSearchResults
							product={currentProduct}
							handleClickonAdd={handleClickonAdd}
						/>
					) : (
						<SingleProductForListing
							product={currentProduct}
							handleClickonAdd={handleClickonAdd}
							handleClickOnFavourite={handleClickOnFavourite}
							shouldShowSignInToBuy={shouldShowSignInToBuy}
							onMouseOver={handleMouseOver}
							onMouseLeave={handleMouseLeave}
						/>
					)}
				</>
			)}
		</>
	);
};

export default SingleProduct;
