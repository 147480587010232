import React from "react";

const SearchSuggestions = () => {
	return (
		<div className="col-l txt-suggestion">
			<h5 className="pad5 bdr-b">Search Suggestions</h5>
			<div className="pad bdr-b">Walkers</div>
			<div className="txt-md-c">
				<div className="pad20"></div>
			</div>
		</div>
	);
};

export default SearchSuggestions;
