import React, { useState, useEffect } from "react";
import { CartService } from "../Services/CartService";
import eventBus from "../classes/EventBus";
import { Events } from "../classes/AppEnum";

const CartLink = ({ refresh, onClick }) => {
	const [cartTotals, setCartTotals] = useState({
		numberOfDifferentProducts: 0,
		subtotal: 0,
		vat: 0,
		numberOfCases: 0,
	});

	const getCartTotals = () => {
		CartService.getCartTotal(
			(numberOfDifferentProducts, subtotal, vat, numberOfCases, error) => {
				setCartTotals({
					numberOfDifferentProducts,
					subtotal,
					vat,
					numberOfCases,
				});
			}
		);
	};
	useEffect(() => {
		eventBus.on(Events.productQuantityChangedInCart, (data) => {
			getCartTotals();
		});
		eventBus.on(Events.UserPreferencesChanged, () => {
			getCartTotals();
		});

		getCartTotals();

		return function cleanup() {
			eventBus.remove(Events.productQuantityChangedInCart);
			eventBus.remove(Events.UserPreferencesChanged);
		};
	}, [refresh]);
	return (
		<div onClick={onClick} className="cart-tog">
			<div className="line1">
				{cartTotals.numberOfCases} Cases ({cartTotals.numberOfDifferentProducts}{" "}
				lines)
			</div>
			<div className="line2 ammount">
				Subtotal: <span className="ammount">£{cartTotals.subtotal}</span>
			</div>
		</div>
	);
};

export default CartLink;
