import React, { useState, useEffect, useRef } from "react";
import SingleProduct from "./Single-Product";

import Grid from "./grid";
import { ProductService } from "../Services/ProductService";

import eventBus from "../classes/EventBus";
import { Events, SideFilters, SpecialFilter } from "../classes/AppEnum";
import LoadingIndicator from "./loadingIndicator";
import { AppConstants } from "../misc/Constants";
import FilterBox from "./filterBox";
import Theme from "./theme";
import { UserService } from "../Services/UserService";
import { useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ProductsGrid = ({ supplier, supplierShopImageKey }) => {
	const navigate = useNavigate();

	const [products, _setProducts] = useState([]);
	const {
		specialFilterName,
		categoryId,
		subCategoryId,
		productCodes,
		searchText,
	} = useParams();
	const [categorisedProducts, setCategorisedProducts] = useState({});
	const [productsLoading, _setProductsLoading] = useState(true);
	// const [selectedSupplierShopImageKey, _setSelectedSupplierShopImageKey] =
	// 	useState(null);
	const [sideFilterOption, _setSideFilterOption] = useState({
		sideFilter: null,
		filterOption: null,
	});

	const productsStateRef = useRef(products);
	const productsLoadingStateRef = useRef(productsLoading);
	const sideFilterOptionStateRef = useRef(sideFilterOption);
	// const selectedSupplierShopImageKeyStateRef = useRef(
	// 	selectedSupplierShopImageKey
	// );

	const location = useLocation();

	let specialFilter = SpecialFilter.getAll().filter(
		(specialFilter) => specialFilter.NAME == specialFilterName
	)[0];

	let recomendedProducts = [];
	const setProducts = (updatedProducts) => {
		productsStateRef.current = updatedProducts;
		_setProducts(updatedProducts);
	};

	// const setSelectedSupplierShopImageKey = (imageKey) => {
	// 	if (selectedSupplierShopImageKeyStateRef.current === imageKey) {
	// 		selectedSupplierShopImageKeyStateRef.current = null;
	// 		_setSelectedSupplierShopImageKey(null);
	// 	} else {
	// 		selectedSupplierShopImageKeyStateRef.current = imageKey;
	// 		_setSelectedSupplierShopImageKey(imageKey);
	// 	}
	// };

	const setProductsLoading = (isLoading) => {
		productsLoadingStateRef.current = isLoading;
		_setProductsLoading(isLoading);
	};
	const setSideFilterOption = (sideFilter, filterOption) => {
		sideFilterOptionStateRef.current = { sideFilter, filterOption };
		_setSideFilterOption({ sideFilter, filterOption });
	};
	const handleSideFilterOptionSelection = (sideFilter, filterOption) => {
		setSideFilterOption(sideFilter, filterOption);
		setProducts([]);
		getProducts();
	};
	const handleSideFilterOptionDeSelection = (sideFilter, filterOption) => {
		setSideFilterOption(null, null);
		setProducts([]);
		getProducts();
	};

	const handleSupplierShopImageSelection = (imageKey) => {
		navigate("/supplierShop/" + supplier.supplierCode + "/" + imageKey);
		navigate(0);

		// if (selectedSupplierShopImageKey !== imageKey) {
		// 	setSelectedSupplierShopImageKey(imageKey);
		// } else {
		// 	setSelectedSupplierShopImageKey(null);
		// }
		// setProducts([]);
		// getProducts();
	};

	useEffect(() => {
		eventBus.on(Events.UserPreferencesChanged, () => {
			setProducts([]);
			getProducts();
		});
		setSideFilterOption(null, null);
		setProducts([]);
		getProducts();

		window.addEventListener("scroll", listenToScroll);

		return function cleanup() {
			window.removeEventListener("scroll", listenToScroll);
			eventBus.remove(Events.UserPreferencesChanged);
		};
	}, [specialFilterName, searchText, categoryId, subCategoryId, supplier]);

	const getProducts = () => {
		if (
			productCodes != null ||
			(supplier != null && supplierShopImageKey != null)
		) {
			getProductByProductCodes();
			return;
		}

		if (
			searchText != null &&
			searchText.length > 0 &&
			productsStateRef.current.length == 0
		) {
			setProductsLoading(true);
			ProductService.getSearchedProducts(
				searchText,
				productsStateRef.current.length + 1,
				AppConstants.Products_Limit,
				(newProducts, cartInstructions, error) => {
					setProductsLoading(false);
					handleResponse(newProducts, cartInstructions, error);
				}
			);
			return;
		}

		setProductsLoading(true);
		ProductService.getProducts(
			specialFilter,
			searchText ?? "",
			categoryId ?? "",
			subCategoryId ?? "",

			sideFilterOptionStateRef.current != null
				? sideFilterOptionStateRef.current.sideFilter
				: null,
			sideFilterOptionStateRef.current != null
				? sideFilterOptionStateRef.current.filterOption
				: null,
			productsStateRef.current.length + 1,
			AppConstants.Products_Limit,
			supplier != null ? supplier.supplierCode : null,
			"",
			supplierShopImageKey,
			(newProducts, cartInstructions, error) => {
				setProductsLoading(false);
				handleResponse(newProducts, cartInstructions, error);
			}
		);
	};
	const getProductByProductCodes = () => {
		setProductsLoading(true);
		ProductService.getProductsByProductCodes(
			supplier != null ? supplier.supplierCode : null,
			supplierShopImageKey,
			productCodes,
			(newProducts, cartInstructions, error) => {
				setProductsLoading(false);
				handleResponse(newProducts, cartInstructions, error);
			}
		);
	};
	const handleResponse = (newProducts, cartInstructions, error) => {
		if (error != null) {
			alert(error);
			return;
		}

		setProducts(productsStateRef.current.concat(newProducts));
		setCategorisedProducts(
			ProductService.categorisedProducts(productsStateRef.current)
		);
	};
	const listenToScroll = () => {
		const winScroll =
			document.documentElement.scrollTop || document.body.scrollTop;

		const height =
			document.documentElement.scrollHeight -
			document.documentElement.clientHeight;

		const scrolled = winScroll / height;

		if (
			scrolled >= 0.9 &&
			productsLoadingStateRef.current == false &&
			productCodes == null &&
			(supplier == null || supplierShopImageKey == null)
		) {
			getProducts();
		}
		if (window.scrollY > 1100) {
			document.getElementById("filterbox").classList.add("filtershow");
		} else {
			document.getElementById("filterbox").classList.remove("filtershow");
		}
	};
	const getCategoryAdUrl = (categoryName) => {
		return UserService.getCategoryAdUrl(categoryName);
	};

	return (
		<div>
			{/* if Products are loading and there's no product yet then don't show anything. */}
			{/* {(productsLoading && products.length == 0) || ( */}
			<div>
				<section className="container pad20-t">
					<div className="prod-left-pane">
						{SideFilters.getAll().map((sideFilter) => {
							return (
								<FilterBox
									key={sideFilter.id}
									title={sideFilter.title}
									checkedOption={
										sideFilterOption != null &&
										sideFilterOption.sideFilter != null &&
										sideFilterOption.sideFilter.id == sideFilter.id
											? sideFilterOption.filterOption
											: null
									}
									filterOptions={sideFilter.options}
									onSelect={(filterOption) =>
										handleSideFilterOptionSelection(sideFilter, filterOption)
									}
									onDeselect={(filterOption) =>
										handleSideFilterOptionDeSelection(sideFilter, filterOption)
									}
								/>
							);
						})}

						{products.length > 0 && (
							<>
								<div className="filter prod-list grid-view">
									<SingleProduct
										title="Advertised Product"
										product={products[products.length - 1]}
										listedInCart={false}
										listedInSearchResults={false}
									/>
								</div>
								{products.length > 1 && (
									<div className="filter prod-list grid-view">
										<SingleProduct
											title="Recomended For You"
											product={products[products.length - 2]}
											listedInCart={false}
											listedInSearchResults={false}
										/>
									</div>
								)}
							</>
						)}
						<div className="eor"></div>
					</div>
					{supplier != null &&
						(supplierShopImageKey == null || supplierShopImageKey === "") && (
							<div className="prod-list-pane">
								<section className="supplier-banner mar-b">
									<Theme
										templateId={supplier.Template[0].templateId}
										images={supplier.Template[0].images}
										onClickOnUpload={() => {}}
										onImageSelected={handleSupplierShopImageSelection}
									/>
								</section>
							</div>
						)}

					{(productsLoading && products.length == 0) || (
						<>
							{Object.keys(categorisedProducts).map((categoryName) => {
								return (
									<Grid>
										<div className={"prod-cat " + categoryName.toLowerCase()}>
											<span>{categoryName}</span>
										</div>
										{getCategoryAdUrl(categoryName) != "" && (
											<div
												style={{
													backgroundImage: `url(${getCategoryAdUrl(
														categoryName
													)})`,
												}}
												className={
													UserService.isSignedIn()
														? "cat-banner"
														: "cat-banner nolog"
												}
											>
												{/* <img
													src={UserService.getCategoryAdUrl(categoryName)}
												></img> */}
											</div>
										)}
										{categorisedProducts[categoryName].map((product) => {
											return (
												<SingleProduct key={product.id} product={product} />
											);
										})}
										<div className="eor"></div>
									</Grid>
								);
							})}
						</>
					)}
				</section>
			</div>
			{/* )} */}
			{productsLoading && (
				<div
					style={{
						textAlign: "center",
						// height: "70px",
						// paddingTop: "30px",
					}}
				>
					<LoadingIndicator className="loader" />
				</div>
			)}
		</div>
	);
};
export default ProductsGrid;
