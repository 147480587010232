import React from "react";

const B3A = ({ imageUrl, onClick }) => {
	return (
		<div className="mar20-t" onClick={onClick}>
			<img width="100%" src={imageUrl} />
		</div>
	);
};

export default B3A;
