import React, { useEffect, useState } from "react";
import { CartService } from "../Services/CartService";
import Order from "./Order";
import LoadingIndicator from "./loadingIndicator";

const OrderHistory = () => {
	const [orders, setOrders] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getOrders = () => {
		setIsLoading(true);
		CartService.getOrders((orders, error) => {
			setIsLoading(false);
			if (error != null) {
				alert(error);
			} else {
				setOrders(orders);
			}
		});
	};
	useEffect(() => {
		getOrders();
		return () => {};
	}, []);

	return (
		<>
			{isLoading ? (
				<LoadingIndicator
					text1="Loading order history ..."
					text2="This may take a while ..."
					className="loader"
				/>
			) : (
				<section id="order-history" className="container">
					<article className="line40 pad20-t">
						<div className="col-md-6">
							<h1>Order History</h1>
						</div>
						<div className="col-md-6 txt-md-r">
							{/* <img src="images/select-date-range-icon.png" /> */}
							<a href="#">Displaying {orders.length} orders</a>
						</div>
						<div className="eor"></div>
					</article>
					<article className="pad20-t restbl">
						<table
							className="table2"
							width="100%"
							cellpadding="0"
							cellspacing="0"
							border="0"
						>
							<tr>
								<th className="txt-md-l">Order Date</th>
								<th className="txt-md-l">Order No</th>
								<th className="txt-md-l">Invoice Date</th>
								<th className="txt-md-r">Invoice No</th>
								<th className="txt-md-r">Qunatity</th>
								{/* <th className="txt-md-r">Split Packs</th> */}
								<th className="txt-md-r">0% Goods</th>
								<th className="txt-md-r">5% Goods Ex VAT</th>
								<th className="txt-md-r">5% VAT</th>
								<th className="txt-md-r">20% Goods Ex VAT</th>
								<th className="txt-md-r">20% VAT</th>
								<th className="txt-md-r">Total Goods</th>
								<th className="txt-md-r">Total VAT</th>
								<th className="txt-md-r">Invoice Total</th>
							</tr>
							{orders.map((order, index) => {
								return <Order order={order} key={index} />;
							})}
						</table>
					</article>
				</section>
			)}
		</>
	);
};

export default OrderHistory;