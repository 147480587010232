import React, { useState } from "react";
import FileBrowser from "./FileBrowser";
import ImportedProducts from "./importedProducts";
import BarcodeColumnSelector from "./readBarcodesFromFile";

const ImportOrder = ({ onSuccessfulImportOfProducts }) => {
	const [fileData, setFileData] = useState(null);
	const [barcodes, setBarcodes] = useState(null);
	const [quantities, setQuantities] = useState({});

	const getBarcodesAtColumn = (barcodeColumnNumber, quantityColumnNumber) => {
		let barcodes = [];
		let quantities = {};
		fileData.forEach((row) => {
			if (
				row.length >= barcodeColumnNumber &&
				row[barcodeColumnNumber - 1].length > 0
			) {
				barcodes.push(row[barcodeColumnNumber - 1]);

				if (quantityColumnNumber && row.length >= quantityColumnNumber) {
					quantities[row[barcodeColumnNumber - 1]] =
						row[quantityColumnNumber - 1];
				} else {
					if (quantities[row[barcodeColumnNumber - 1]] != null) {
						quantities[row[barcodeColumnNumber - 1]] += 1;
					} else {
						quantities[row[barcodeColumnNumber - 1]] = 1;
					}
				}
			}
		});
		setQuantities(quantities);
		setBarcodes(barcodes);
	};

	return (
		<>
			{barcodes ? (
				<ImportedProducts
					barcodes={barcodes}
					quantities={quantities}
					onSuccessfulImportOfProducts={onSuccessfulImportOfProducts}
					onClickOnPrevious={() => {
						setBarcodes(null);
						setFileData(null);
					}}
				/>
			) : (
				<div id="import" className="txt-md-l box500  form1">
					{fileData == null ? (
						<FileBrowser
							onClickOnNext={(data) => {
								if (data && data.length > 0) setFileData(data);
							}}
						/>
					) : (
						<BarcodeColumnSelector
							numberOfColumns={fileData[0].length}
							onColumnSelect={(barcodeColumnNumber, quantityColumnNumber) => {
								getBarcodesAtColumn(barcodeColumnNumber, quantityColumnNumber);
							}}
							onClickOnPrevious={() => {
								setFileData(null);
							}}
						/>
					)}
				</div>
			)}
		</>
	);
};

export default ImportOrder;
