import React from "react";

const NewsLetter = () => {
	return (
		<article id="news-letter">
			<h1 className="pad20-b">Get the latest News and Deals!</h1>
			<h3 className="txt-md-c">
				<strong>
					Stay OPTED IN to get all the deals and latest updates by SMS and
					Email. Help us reduce our carbon footprint.
				</strong>
			</h3>
			<form className="pad20-t">
				<div className="col-md-4 col-sm-12">
					<label className="pad-lr">Email Address</label>
					<div className="pad-lr">
						<input type="text" />
					</div>
				</div>
				<div className="col-md-3 col-sm-4">
					<label className="pad-lr">Contact Number</label>
					<div className="pad-lr">
						<input type="text" />
					</div>
				</div>
				<div className="col-md-3 col-sm-4">
					<label className="pad-lr">Account Number</label>
					<div className="pad-lr">
						<input type="text" />
					</div>
				</div>
				<div className="col-md-2 col-sm-4">
					<label className="pad-lr">&nbsp;</label>
					<div className="pad-lr">
						<input type="submit" value="JOIN" />
					</div>
				</div>
				<div className="eor"></div>
			</form>
			<div className="txt-md-c bottom-line">
				By entering your details you consent to being sent marketing material by
				United.
			</div>
		</article>
	);
};

export default NewsLetter;
