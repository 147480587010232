import React, { useEffect, useState } from "react";
import B1 from "./b1";
import B2A from "./b2a";
import B2B from "./b2b";

const ThemeTwo = ({ images }) => {
	const [imagesHash, setImagesHash] = useState({});

	useEffect(() => {
		let updatedImagesHash = {};
		images.forEach((image) => {
			if (image.key == "b1") {
				updatedImagesHash.b1 = image;
			} else if (image.key == "b2-a") {
				updatedImagesHash.b2a = image;
			} else if (image.key == "b2-b") {
				updatedImagesHash.b2b = image;
			}
		});

		setImagesHash(updatedImagesHash);
	}, [images]);

	return (
		<>
			<B1
				onClick={() => {}}
				imageUrl={imagesHash.b1 == null ? "" : imagesHash.b1.imageFullPath}
			/>
			<article className="pad-t supplier-banner-wrap">
				<div className="row">
					<div className="col-md-6">
						<B2A
							imageUrl={
								imagesHash.b2a != null ? imagesHash.b2a.imageFullPath : ""
							}
						/>
					</div>
					<div className="col-md-6">
						<B2B
							imageUrl={
								imagesHash.b2b != null ? imagesHash.b2b.imageFullPath : ""
							}
						/>
					</div>
					<div className="eor"></div>
				</div>
			</article>
		</>
	);
};

export default ThemeTwo;
