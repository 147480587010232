import React from "react";
import { AccountLink } from "../classes/AppEnum";
import MakePayment from "../classes/MakePayment";
import ExistingCustomerRegistration from "./existing_customer_registration";
import ForgotPassword from "./forgot_password";
import ImportOrder from "./importOrderFromFile";
import OrderHistory from "./OrderHistory";
import UserDetails from "./userDetails";
import OrderDetail from "./order_detail";

const AccountLinkDetails = ({ accountLink, onSuccessfulImportOfProducts }) => {
	return (
		<>
			{accountLink == AccountLink.ORDER_HISTORY && <OrderHistory />}
			{accountLink == AccountLink.ORDER_DETAIL && <OrderDetail />}

			{accountLink == AccountLink.MAKE_PAYMENT && <MakePayment />}
			{accountLink == AccountLink.IMPORT_ORDER && (
				<ImportOrder
					onSuccessfulImportOfProducts={onSuccessfulImportOfProducts}
				/>
			)}
			{accountLink == AccountLink.USER_DETAILS && <UserDetails />}
			{accountLink == AccountLink.FORGOT_PASSWORD && <ForgotPassword />}
			{accountLink == AccountLink.REGISTER_EXISTING_CUSTOMER && (
				<ExistingCustomerRegistration />
			)}
		</>
	);
};

export default AccountLinkDetails;