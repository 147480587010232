import React, { useEffect } from "react";
import ProductsGrid from "./productsGrid";
import { useParams } from "react-router-dom";
import PrivateRoute from "./private_route";
import { SpecialFilter as SpecialFilterEnum } from "../classes/AppEnum";
const SpecialFilter = () => {
	const { specialFilterName } = useParams();

	useEffect(() => {
		console.log(specialFilterName);
	}, [specialFilterName]);
	return specialFilterName == SpecialFilterEnum.OVER_FOURTY_PERCENT_POR.NAME ||
		specialFilterName == SpecialFilterEnum.RETAIL_CLUB_PROMOTIONS.NAME ||
		specialFilterName == SpecialFilterEnum.FAVOURITES.NAME ? (
		<PrivateRoute>
			<ProductsGrid />
		</PrivateRoute>
	) : (
		<ProductsGrid />
	);
};

export default SpecialFilter;
