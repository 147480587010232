import React from "react";
import { UserService } from "../Services/UserService";
import defaultUserImage from "../assets/user-photo-default.png";
import ChangePassword from "./ChangePassword.js";

import { useState } from "react";

const UserDetails = () => {
	const [showResetPassword, setShowResetPassword] = useState(false);
	return (
		<div className="pad-b">
			{showResetPassword ? (
				<ChangePassword
					onClickOnCancel={() => {
						setShowResetPassword(false);
					}}
				/>
			) : (
				<CurrentDetails
					onClickOnPassword={() => {
						setShowResetPassword(true);
					}}
				/>
			)}
		</div>
	);
};

const CurrentDetails = ({ onClickOnPassword }) => {
	return (
		<div className="txt-md-c box500 act-detail">
			<h1 className="pad-tb line40">Account Details</h1>
			<div className="pad-tb">
				<div className="col-md-2">
					<img src={defaultUserImage} />
				</div>
				<div className="col-md-10">
					<div className="udetail">
						<div className="col-sm-4 txt-sm-r txt-xs-l">User Name:</div>
						<div className="push-sm-1 col-sm-7 txt-sm-l txt-xs-c">
							{UserService.contactName()}
						</div>
						<div className="eor"></div>
					</div>
					<div className="udetail">
						<div className="col-sm-4 txt-sm-r txt-xs-l">User Phone:</div>
						<div className="push-sm-1 col-sm-7 txt-sm-l txt-xs-c">
							{UserService.mobile()}
						</div>
						<div className="eor"></div>
					</div>
					<div className="udetail">
						<div className="col-sm-4 txt-sm-r txt-xs-l">User Email:</div>
						<div className="push-sm-1 col-sm-7 txt-sm-l txt-xs-c">
							{UserService.email()}
						</div>
						<div className="eor"></div>
					</div>
				</div>
				<div className="eor"></div>
			</div>
			<div className="pad20-t txt-md-c">
				<input
					className="btn btn320"
					value="Change Password"
					type="button"
					onClick={onClickOnPassword}
				/>
				<div className="eor"></div>
			</div>
		</div>
	);
};

export default UserDetails;
