import { APIService } from "./APIService.js";
import eventBus from "../classes/EventBus.js";
import { Events } from "../classes/AppEnum.js";
import SubCategories from "../components/subCategories.js";

export const UserService = {
	signin: function (accountNo, email, password, callback) {
		APIService.post(
			"login.php",
			{ accountNo, email, password },
			(jsonResponse, error) => {
				this.handleSigninResponse(jsonResponse, error, callback);
			}
		);
	},
	logout: function () {
		localStorage.removeItem("token");
		localStorage.removeItem("contactName");
		localStorage.removeItem("email");
		localStorage.removeItem("mobile");

		// localStorage.removeItem("assignedCategories");
		eventBus.dispatch(Events.UserLoggedOut, null);
	},
	getPreferences: function (callback) {
		APIService.get("get_user_preferences.php", {}, (jsonResponse, error) => {
			if (error != null) {
				callback(null, null, error);
			} else {
				const data = jsonResponse.data;
				const orderTypeName = data.defaultOrderType;
				const orderTypeId = data.defaultOrderTypeId;
				const productTypeName = data.defaultPrdType;
				const productTypeId = data.defaultPrdTypeId;
				let categories = Array.from(jsonResponse.data.Categories);
				let updatedCategories = Array.from(categories);
				if (categories == false) {
					categories = [];
				}
				categories.forEach((category) => {
					if (category.h1Id == 5) {
						category.H2.forEach((subCategory) => {
							if (subCategory.h2Id == 4) {
								subCategory.h2Name = "VAPING & PODS";
								subCategory.H2 = [];
								updatedCategories.push(subCategory);
							}
						});
					}
				});
				localStorage.setItem(
					"assignedCategories",
					JSON.stringify(updatedCategories)
				);
				eventBus.dispatch(Events.AssignedCategoriesUpdated, {});
				callback(
					orderTypeName,
					productTypeName,
					orderTypeId,
					productTypeId,
					null
				);
			}
		});
	},
	setOrderTypePreference: function (orderTypeId, callback) {
		this.setPreference({ orderType: orderTypeId }, (error) => {
			eventBus.dispatch(Events.UserPreferencesChanged, {});
			callback(error);
		});
	},
	setProductTypePreference: function (productTypeId, callback) {
		this.setPreference({ productType: productTypeId }, (error) => {
			eventBus.dispatch(Events.UserPreferencesChanged, {});
			callback(error);
		});
	},
	getAssignedPaymentMethods: function (callback) {
		//this api return payment methods assigned to user and available time slots if he has click & collect in his settings. It also return clientToken for braintree.
		APIService.get("payment_methods.php", {}, (jsonResponse, error) => {
			if (error != null) {
				callback(null, null, null, error);
				return;
			}
			const clientToken = jsonResponse.data.clientToken;
			const paymentMethods = jsonResponse.data.AllPaymentMethods;
			const timeSlots = jsonResponse.data.timeslots
				? jsonResponse.data.timeslots.allSlots
				: [];
			callback(clientToken, paymentMethods, timeSlots, null);
		});
	},
	setPreference: function (params, callback) {
		APIService.post(
			"update_user_preferences.php",
			params,
			(jsonResponse, error) => {
				callback(error);
			}
		);
	},
	getClickAndCollectTimeSlots: function (callback) {
		APIService.get("time_slots.php", {}, (jsonResponse, error) => {
			if (error != null) {
				callback(null, error);
			} else {
			}
		});
	},
	getCategoryAdUrl: function (categoryName) {
		let categoryAdUrl = "";
		if (categoryName != null) {
			let categories = this.assignedCategories();
			if (categories != null) {
				categories.map((category) => {
					if (category.h1Name && category.h1Name == categoryName) {
						categoryAdUrl = category.h1AdvImage;
					}
				});
			}
		}
		return categoryAdUrl;
	},
	userToken: function () {
		return localStorage.getItem("token");
	},
	resetPassword: function (currentPassword, newPassword, callback) {
		APIService.post(
			"reset_pwd.php",
			{ currentPassword, newPassword },
			(jsonResponse, error) => {
				callback(error);
			}
		);
	},
	registerExistingCustomer: function (
		accountNo,
		email,
		postcode,
		salesAlcohol,
		salesTobacco,
		callback
	) {
		APIService.post(
			"register.php",
			{ email, accountNo, postcode, salesAlcohol, salesTobacco },
			(jsonResponse, error) => {
				if (error != null) {
					callback(null, error);
				} else {
					let status = jsonResponse.data.status;
					if (status != 1) {
						callback(null, jsonResponse.data.msg);
					} else {
						callback(jsonResponse.data.msg, null);
					}
				}
			}
		);
	},
	requestNewPassword: function (email, accountNo, callback) {
		APIService.post(
			"request_new_password.php",
			{ email, accountNo },
			(jsonResponse, error) => {
				if (error != null) {
					callback(null, error);
				} else {
					callback(jsonResponse.data.msg, null);
				}
			}
		);
	},
	assignedCategories: function () {
		let categories = JSON.parse(
			localStorage.getItem("assignedCategories") || "[]"
		);
		return categories;
	},
	isSignedIn: function () {
		return this.userToken() != null;
	},
	contactName: function () {
		return localStorage.getItem("contactName");
	},
	email: function () {
		return localStorage.getItem("email");
	},
	mobile: function () {
		return localStorage.getItem("mobile");
	},

	//handle response from APIService and call the callback functions.
	handleSigninResponse: function (jsonResponse, error, callback) {
		if (error != null) {
			callback(null, null, error);
			return;
		}
		const status = jsonResponse.data.status;
		const isSuccessfullySignedIn = status == 200;
		if (isSuccessfullySignedIn) {
			localStorage.setItem("token", jsonResponse.data.token);
			localStorage.setItem("contactName", jsonResponse.data.contactName);
			localStorage.setItem("email", jsonResponse.data.email);
			localStorage.setItem("mobile", jsonResponse.data.mobile);

			let categories = Array.from(jsonResponse.data.Categories);
			console.log(jsonResponse.data.Categories);
			let updatedCategories = Array.from(categories);
			if (categories == false) {
				categories = [];
			}
			categories.forEach((category) => {
				if (category.h1Id == 5) {
					category.H2.forEach((subCategory) => {
						if (subCategory.h2Id == 4) {
							subCategory.h2Name = "VAPING & PODS";
							subCategory.H2 = [];
							updatedCategories.push(subCategory);
						}
					});
				}
			});
			localStorage.setItem(
				"assignedCategories",
				JSON.stringify(updatedCategories)
			);
		}
		callback(isSuccessfullySignedIn, jsonResponse.data.msg, null);
	},
	getBrochure: function (callback) {
		APIService.get("get_brochure.php", {}, (jsonResponse, error) => {
			if (error) {
				callback(null, null, error);
			} else {
				let brochureFileUrl = jsonResponse.data.brochureFile;
				let brochureImageUrl = jsonResponse.data.brochureImage;
				callback(brochureImageUrl, brochureFileUrl, null);
			}
		});
	},
};
