import React from "react";
import { useState } from "react";
import checkmark from "../assets/checkmark.svg";

const B5C = ({ imageUrl, onClick }) => {
	const [selected, setSelected] = useState(false);

	return (
		<div
			className="col-3 mar20-t checkmark"
			onClick={() => {
				setSelected(!selected);
				onClick();
			}}
		>
			<img src={imageUrl} />
			{selected && <img width="100%" className="checkmark" src={checkmark} />}
		</div>
	);
};

export default B5C;
