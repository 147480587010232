import React, { useEffect, useState } from "react";
import B1 from "./b1";
import B3A from "./b3a";
import B5A from "./b5a";
import B5B from "./b5b";
import B3B from "./b3b";
import B5C from "./b5c";
import B5D from "./b5d";
import { Link } from "react-router-dom";
import { useSupplier } from "./SupplierShop";

const ThemeEight = ({ images }) => {
	const [imagesHash, setImagesHash] = useState({});
	const supplierCode = useSupplier() || ""; // Ensure supplierCode is not undefined

	useEffect(() => {
		let updatedImagesHash = {};
		images.forEach((image) => {
			if (image.key) {
				updatedImagesHash[image.key] = image;
			}
		});
		setImagesHash(updatedImagesHash);
	}, [images]);

	return (
		<>
			<Link to={`/supplierShop/${supplierCode}/${imagesHash["b1"]?.key || ""}`}>
				<B1 imageUrl={imagesHash["b1"]?.imageFullPath || ""} />
			</Link>
			<article className="pad-t">
				<div className="row5">
					<div className="col-md-6">
						<div className="pad5-lr">
							<Link
								to={`/supplierShop/${supplierCode}/${
									imagesHash["b3-a"]?.key || ""
								}`}
							>
								<B3A imageUrl={imagesHash["b3-a"]?.imageFullPath || ""} />
							</Link>

							<div className="row5">
								<div className="col-md-6">
									<Link
										to={`/supplierShop/${supplierCode}/${
											imagesHash["b5-c"]?.key || ""
										}`}
									>
										<B5C imageUrl={imagesHash["b5-c"]?.imageFullPath || ""} />
									</Link>
								</div>
								<div className="col-md-6">
									<Link
										to={`/supplierShop/${supplierCode}/${
											imagesHash["b5-d"]?.key || ""
										}`}
									>
										<B5D imageUrl={imagesHash["b5-d"]?.imageFullPath || ""} />
									</Link>
								</div>
								<div className="eor"></div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="pad5-lr">
							<div className="row5">
								<div className="col-md-6">
									<Link
										to={`/supplierShop/${supplierCode}/${
											imagesHash["b5-a"]?.key || ""
										}`}
									>
										<B5A imageUrl={imagesHash["b5-a"]?.imageFullPath || ""} />
									</Link>
								</div>
								<div className="col-md-6">
									<Link
										to={`/supplierShop/${supplierCode}/${
											imagesHash["b5-b"]?.key || ""
										}`}
									>
										<B5B imageUrl={imagesHash["b5-b"]?.imageFullPath || ""} />
									</Link>
								</div>
								<div className="eor"></div>
							</div>
							<Link
								to={`/supplierShop/${supplierCode}/${
									imagesHash["b3-b"]?.key || ""
								}`}
							>
								<B3A imageUrl={imagesHash["b3-b"]?.imageFullPath || ""} />
							</Link>
						</div>
					</div>

					<div className="eor"></div>
				</div>
			</article>
		</>
	);
};

export default ThemeEight;
