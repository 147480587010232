import React, { useState } from "react";
import MUPCalculator from "./MUPCalculator";
import PORCalculator from "./PORCalculator";

const BottomGrid = ({ onClickOnCategoryFocus, onClickOnDirectToStore }) => {
	const [shouldShowPORCalculator, _setShouldShowPORCalculator] = useState(true);
	return (
		<section id="calculators" className="row">
			<article className="col-md-4 col-sm-12">
				<div className="calculator">
					<div className="tabs">
						<div
							className="tab por"
							onClick={() => {
								_setShouldShowPORCalculator(true);
							}}
						>
							POR Calculator
						</div>
						<div
							className="tab mup"
							onClick={() => {
								_setShouldShowPORCalculator(false);
							}}
						>
							MUP Calculator
						</div>
					</div>

					{/* Calculators */}
					{shouldShowPORCalculator ? <PORCalculator /> : <MUPCalculator />}
				</div>
			</article>
			<article className="col-md-4 col-sm-6">
				<div className="direct-to-store">
					<h2>Direct To Store</h2>
					<div className="content">
						<div className="text">
							Explore the selection of support services designed to help you
							save more and sell more. Take a look at our supplier partners.
						</div>
						<div className="pad-t">
							<button className="btn" onClick={onClickOnDirectToStore}>
								Explore
							</button>
						</div>
					</div>
				</div>
			</article>
			<article className="col-md-4 col-sm-6">
				<div className="category-focus">
					<h2>Category Focus</h2>
					<div className="content">
						<div className="text">
							View the latest Plan for Profit category focused merchandising
							guides to maximise your sales.
						</div>
						<div className="pad-t">
							<button className="btn" onClick={onClickOnCategoryFocus}>
								Explore
							</button>
						</div>
					</div>
				</div>
			</article>
			<article className="eor"></article>
		</section>
	);
};

export default BottomGrid;
