import React, { useEffect, useState } from "react";
import B1 from "./b1";

const ThemeOne = ({ images }) => {
	const [imagesHash, setImagesHash] = useState({});

	useEffect(() => {
		let updatedImagesHash = {};
		images.forEach((image) => {
			if (image.key == "b1") {
				updatedImagesHash.b1 = image;
			}
		});

		setImagesHash(updatedImagesHash);
	}, [images]);

	return (
		<B1
			onClick={() => {}}
			imageUrl={imagesHash.b1 == null ? "" : imagesHash.b1.imageFullPath}
		/>
	);
};

export default ThemeOne;