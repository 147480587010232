import React from "react";
import OrderType from "./orderType";
import { OrderTypes } from "../classes/AppEnum.js";
import xImage from "../assets/x.svg";
import ProductTypesList from "./productTypesList";
import { useState } from "react";
import { UserService } from "../Services/UserService";
import { useEffect } from "react";
const SelectOrderType = ({ orderTypeId, handleClickOnX }) => {
	const [selectedOrderTypeId, setSelectedOrderTypeId] = useState(orderTypeId);

	const handleOrderTypeSelection = (orderTypeId) => {
		//Call the service method to save the order type preference.
		UserService.setOrderTypePreference(orderTypeId, (error) => {
			if (error != null) {
				alert(error);
				return;
			}
			setSelectedOrderTypeId(orderTypeId);
		});
	};

	useEffect(() => {
		UserService.getPreferences(() => {});
	}, []);
	return (
		<section className="dlog" id="orprotype" style={{ zIndex: 1000 }}>
			<article className="dlogbox">
				<h3 className="pad-tb">
					Select Order Type
					<img
						className="x pad-r"
						width="20px; height 20px;"
						src={xImage}
						onClick={handleClickOnX}
					/>
				</h3>
				<div>
					{selectedOrderTypeId == null ||
					selectedOrderTypeId == OrderTypes.CLICK_AND_COLLECT.id ? (
						<OrderType
							heading={OrderTypes.CLICK_AND_COLLECT.name}
							text={OrderTypes.CLICK_AND_COLLECT.description}
							image={OrderTypes.CLICK_AND_COLLECT.image}
							shoulHaveRightBorder={true}
							onClick={() => {
								handleOrderTypeSelection(OrderTypes.CLICK_AND_COLLECT.id);
							}}
							onClickOnChange={() => setSelectedOrderTypeId(null)}
						/>
					) : (
						<OrderType
							heading={OrderTypes.DELIVERED.name}
							text={OrderTypes.DELIVERED.description}
							image={OrderTypes.DELIVERED.image}
							shoulHaveRightBorder={true}
							onClick={() => {
								handleOrderTypeSelection(OrderTypes.DELIVERED.id);
							}}
							onClickOnChange={() => setSelectedOrderTypeId(null)}
						/>
					)}
					{selectedOrderTypeId != null ? (
						<ProductTypesList onCancel={() => setSelectedOrderTypeId(null)} />
					) : (
						<OrderType
							heading={OrderTypes.DELIVERED.name}
							text={OrderTypes.DELIVERED.description}
							image={OrderTypes.DELIVERED.image}
							shoulHaveRightBorder={false}
							onClick={() => {
								handleOrderTypeSelection(OrderTypes.DELIVERED.id);
							}}
							onClickOnChange={() => {}}
						/>
					)}

					<div className="eor"></div>
				</div>
			</article>
		</section>
	);
};

export default SelectOrderType;
