import React, { useEffect, useState } from "react";
import SearchBar from "./searchBar";
import { ProductService } from "../Services/ProductService";
import SearchResults from "./SearchResults";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
//This component has a searchbar and it shows product filtered by text in searchbar
const Search = ({ refresh }) => {
	const [products, setProducts] = useState([]);
	const searchTextRef = useRef("");
	const navigate = useNavigate();
	let timeout = 0;

	//Get products based on search text and set state
	const getProducts = (searchText) => {
		// searchTextRef.current = searchText;

		//if search text is empty then just remove all products from array
		if (searchTextRef.current.length == 0) {
			setProducts([]);
			return;
		}
		//if search text isn't empty then get products filtered by searchText from ProductService. start from index 0 and get 5 products.
		ProductService.getSearchedProducts(
			searchTextRef.current,
			1,
			10,
			(searchedProducts, searchTextInRequest, error) => {
				//if there is error then don't change state and show alert with error
				if (error != null) {
					alert(error);
				} else {
					//if this response is for current search text of searchbar then use this response otherwise discard the respomse.
					if (searchTextRef.current == searchTextInRequest) {
						setProducts(searchedProducts);
					}
				}
			}
		);
	};

	useEffect(() => {
		searchTextRef.current = "";
		setProducts([]);
	}, [refresh]);

	return (
		//css class searchbar
		<div className="searchbar">
			<SearchBar
				defaultSearchText={searchTextRef.current}
				onChange={(searchText) => {
					searchTextRef.current = searchText;
					if (timeout) clearTimeout(timeout);
					timeout = setTimeout(() => {
						getProducts(searchText);
					}, 500);
				}}
				onSubmit={(searchText) => {
					if (searchText.length > 0) {
						searchTextRef.current = "";
						setProducts([]);
						navigate("/products/search/" + searchText);
					}
				}}
				refresh={refresh}
			/>
			<SearchResults
				products={products}
				onClose={() => {
					setProducts([]);
				}}
			/>
		</div>
	);
};

export default Search;
