import React, { useEffect, useRef } from "react";
import barcode from "../assets/barcode.svg";
import $ from 'jquery';
import { Link } from "react-router-dom";

const SearchBar = ({ defaultSearchText, onChange, onSubmit, refresh }) => {
	const searchTextBarRef = useRef(null);

	const handleTextChange = (newText) => {
		searchTextBarRef.current.value = newText;
		onChange(newText);
	};
	useEffect(() => {
		searchTextBarRef.current.value = defaultSearchText;
	}, [refresh]);

	const opencam = () => {
		$("#camscan").trigger("click");
	};
	return (
		<form
			onSubmit={(event) => {
				event.preventDefault();
				onSubmit(searchTextBarRef.current.value);
			}}
		>
			<input
				className="search-suggestion-tog search-in"
				type="text"
				ref={searchTextBarRef}
				onChange={(e) => {
					handleTextChange(e.target.value);
				}}
			/>
			{/* <button className="barcode" onClick={opencam}>
				<img src={barcode} />
			</button> */}
			<input
				hidden="hidden"
				type="file"
				id="camscan"
				capture="environment"
				accept="image/*"
			/>

			<input className="search-btn" type="submit" value="" />
		</form>
	);
};

export default SearchBar;
