import React, { useRef } from "react";
import DropIn from "braintree-web-drop-in-react";

const BraintreeUI = ({ amount, clientToken, onGettingNonce }) => {
	const braintreeInstance = useRef(null);
	const handleSubmit = () => {
		braintreeInstance.current.requestPaymentMethod(function (
			requestPaymentMethodErr,
			payload
		) {
			if (requestPaymentMethodErr != null) {
				alert(requestPaymentMethodErr);
				return;
			}
			onGettingNonce(payload.nonce);
		});
	};
	return (
		<div id="dropin-wrapper">
			<DropIn
				options={{
					authorization: clientToken,
					threeDSecure: { amount: amount },
					applePay: {
						displayName: "United Wholesale Grocers Limited",
						paymentRequest: {
							total: {
								label: "United Wholesale Grocers Limited",
								amount: amount,
							},
							// We recommend collecting billing address information, at minimum
							// billing postal code, and passing that billing postal code with all
							// Apple Pay transactions as a best practice.
							requiredBillingContactFields: ["postalAddress"],
						},
					},
					googlePay: {
						googlePayVersion: 2,
						merchantId: "merchant-id-from-google",
						transactionInfo: {
							totalPriceStatus: "FINAL",
							totalPrice: amount,
							currencyCode: "GBP",
						},
						allowedPaymentMethods: [
							{
								type: "CARD",
								parameters: {
									// We recommend collecting and passing billing address information with all Google Pay transactions as a best practice.
									billingAddressRequired: true,
									billingAddressParameters: {
										format: "FULL",
									},
								},
							},
						],
					},
				}}
				onInstance={(instance) => (braintreeInstance.current = instance)}
				onError={(error) => {
					alert(error);
				}}
			/>
			<div className="pad-tb txt-md-c">
				<input
					className="btn btn-full"
					type="submit"
					value="Secure Checkout"
					onClick={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default BraintreeUI;
