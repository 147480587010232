import React, { useState, useEffect } from "react";
import logo from "../assets/logo.svg";
import Search from "./Search";
import AccountLink from "./accountLink";
import CartLink from "./cartLink";
import { UserService } from "../Services/UserService";
import togmenuicon from "../assets/togmenu.svg";
import cartIcon from "../assets/cart-icon.svg";
import $ from 'jquery';
import eventBus from "../classes/EventBus";
import { Events } from "../classes/AppEnum";
import { Link } from "react-router-dom";
const MidBar = ({
	refresh,
	onCartClick,
	onLogoutClick,
	onAccountLinksDisplay,
	displayCategories,
	handleSignin,
}) => {
	const [shouldDisplayCartLink, setShouldDisplayCartLink] = useState(null);

	const handleSignInLocally = () => {
		if (UserService.isSignedIn()) {
			setShouldDisplayCartLink(true);
		} else {
			setShouldDisplayCartLink(false);
		}
	};
	useEffect(() => {
		eventBus.on(Events.SigninBoxShouldBeShown, () => {
			if (window.innerWidth <= 1260) {
				/// should be called on mobile or tablet only.
				togfilter();
			}
		});

		handleSignInLocally();
		if (displayCategories) {
			togfilter();
		} else {
			// xfilterbar();
		}
	}, [refresh]);

	const togmenu = () => {
		$("header .topbar").show().animate({ left: "0%" });
	};

	const togfilter = () => {
		$("header .filterbar").show();
		$("header .filterbar .shadewrap").show().animate({ left: "0%" });

		/*
		let filterpos = $("header .filterbar").css('right');
		if(filterpos == "0px"){ 
			$("header .filterbar").show().animate({ left: "-100%" });
		}
		else{
			$("header .filterbar").show().animate({ left: "0%" });
		}
		*/
	};

	const xfilterbar = () => {
		$("header .filterbar").animate({ left: "-100%" }).show();
	};

	return (
		<div className="midbar">
			<img className="togmenu" src={togmenuicon} onClick={togfilter} />
			<img className="togcart" src={cartIcon} onClick={onCartClick} />
			<Link className="logo" to="/">
				<img src={logo} />
			</Link>
			<Search refresh={refresh} />
			<AccountLink
				onSignIn={() => {
					handleSignInLocally();
					handleSignin();
				}}
				onLogoutClick={onLogoutClick}
				onAccountLinksDisplay={onAccountLinksDisplay}
			/>
			{shouldDisplayCartLink && (
				<CartLink refresh={refresh} onClick={onCartClick} />
			)}
		</div>
	);
};

export default MidBar;