import React from "react";
import { UserService } from "../Services/UserService";
import { Navigate, useParams } from "react-router-dom";
import { SpecialFilter } from "../classes/AppEnum";

const PrivateRoute = ({ children }) => {
	return UserService.isSignedIn() ? children : <Navigate to="/" />;
};

export default PrivateRoute;
