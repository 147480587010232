import clickAndCollectIcon from "../assets/click-collect.svg";
import deliveredIcon from "../assets/delivered.svg";
import { UserService } from "../Services/UserService";

export const CartRequirementsType = {
	minimumNumberOfCases: "1",
	fixedNumberOfCases: "2",
	minimumAmount: "3",
};

export const Events = {
	UserPreferencesChanged: "UserPreferencesChanged",
	productQuantityChangedInCart: "productQuantityChangedInCart",
	UserLoggedOut: "UserLoggedOut",
	AssignedCategoriesUpdated: "AssignedCategoriesUpdated",
	SigninBoxShouldBeShown: "SigninBoxShouldBeShown",
	CartDeleted: "CartDeleted",
};
export const TopbarLinks = {
	HOME: {
		text: "Home",
		url: "/",
		id: 1,
	},
	CATEGORY_FOCUS: {
		text: "Category Focus",
		url: "/categoryFocus",
		id: 2,
	},
	DIRECT_TO_STORE: {
		text: "Direct to Store",
		url: "",
		id: 3,
	},
	BROCHURE: {
		text: "Brochure",
		url: "/brochure",
		id: 4,
	},
	GET_THE_APP: {
		text: "Get The App",
		url: "",
		id: 5,
	},
	getAll: () => {
		let links = [
			TopbarLinks.HOME,
			TopbarLinks.CATEGORY_FOCUS,
			TopbarLinks.DIRECT_TO_STORE,
		];
		if (UserService.isSignedIn()) {
			links.push(TopbarLinks.BROCHURE);
		}
		links.push(TopbarLinks.GET_THE_APP);
		return links;
	},
};
export const PaymentMethods = {
	Card: {
		id: 1,
		title: "Debit/Credit Card",
	},
	Bank_Transfer: {
		id: 2,
		title: "Bank Transfer",
	},
	Checque: {
		id: 3,
		title: "Checque",
	},
	getAll: () => {
		return [
			PaymentMethods.Card,
			PaymentMethods.Bank_Transfer,
			PaymentMethods.Checque,
		];
	},
};
export const SideFilters = {
	PackSize: {
		title: "PackSize",
		paramName: "packSize",
		defaultParamValue: "0",
		options: [
			{
				id: 1,
				title: "Singles",
			},
			{
				id: 2,
				title: "Cases",
			},
		],
	},
	getAll: () => {
		return [SideFilters.PackSize];
	},
};
export const ScrollDirections = {
	UP: "up",
	DOWN: "down",
};
export const VATOptions = {
	No_Vat: {
		title: "No Vat",
		percentage: 0,
	},
	Five_Percent: {
		title: "5%",
		percentage: 5,
	},
	Fifteen_Percent: {
		title: "15%",
		percentage: 15,
	},
	Twenty_Percent: {
		title: "20%",
		percentage: 20,
	},
	getAll: () => {
		return [
			VATOptions.No_Vat,
			VATOptions.Five_Percent,
			VATOptions.Fifteen_Percent,
			VATOptions.Twenty_Percent,
		];
	},
};
export const SpecialFilter = {
	ALL: {
		NAME: "All Products",
		PARAMS: {},
	},
	PROMOTIONS: {
		NAME: "Top Promotions",
		PARAMS: { schemes: "NA,SP" },
	},
	RETAIL_CLUB_PROMOTIONS: {
		NAME: "Retail Club Promotions",
		PARAMS: { schemes: "RC" },
	},
	VALUE_RANGE: {
		//These are our own products.
		NAME: "Value Range",
		PARAMS: {},
	},
	ONE_POUND_RANGE: {
		NAME: "£1 Range",
		PARAMS: { rrp: 1 },
	},
	OVER_FOURTY_PERCENT_POR: {
		NAME: "Over 40% POR",
		PARAMS: { por: 40 },
	},
	FAVOURITES: {
		NAME: "Favourites",
		PARAMS: { filter: 3 },
	},

	getAll: () => {
		let filters = [
			// SpecialFilter.ALL,
			SpecialFilter.PROMOTIONS,
			SpecialFilter.ONE_POUND_RANGE,
			SpecialFilter.VALUE_RANGE,
			SpecialFilter.OVER_FOURTY_PERCENT_POR,
		];
		if (UserService.isSignedIn()) {
			filters.push(SpecialFilter.FAVOURITES);
			filters.splice(1, 0, SpecialFilter.RETAIL_CLUB_PROMOTIONS);
		}
		return filters;
	},
};

export const ExternalFileType = {
	CATEGORY_FOCUS: 1,
	BROCHURE: 2,
};

export const ProductTypes = {
	AMBIENT: {
		id: 1,
		name: "Ambient",
	},
	TOBACCO: {
		id: 2,
		name: "Tobacco",
	},
	CHILLED_AND_FROZEN: {
		id: 3,
		name: "Chilled & Frozen",
	},

	getAll: () => {
		return [
			ProductTypes.AMBIENT,
			ProductTypes.TOBACCO,
			ProductTypes.CHILLED_AND_FROZEN,
		];
	},
};

export const AccountLink = {
	ACCOUNT_BALANCE: {
		id: 1,
		title: "Account Balance & Details",
		url: "/accountDetails",
	},
	ORDER_HISTORY: {
		id: 2,
		title: "Order History",
		url: "/orderHistory",
	},
	MAKE_PAYMENT: {
		id: 3,
		title: "Make a Payment",
		url: "/makePayment",
	},
	IMPORT_ORDER: {
		id: 4,
		title: "Import Order",
		url: "/importOrder",
	},
	USER_DETAILS: {
		id: 5,
		title: "My Details",
		url: "/myDetails",
	},
	TOBACCO_TRACK_AND_TRACE: {
		id: 6,
		title: "Tobacco Track & Trace",
		url: "/tobaccoTrackAndTrace",
	},
	RETAIL_HUB: {
		id: 7,
		title: "Retail Hub",
		url: "/retailHub",
	},
	FORGOT_PASSWORD: {
		id: 8,
		title: "Forgot Password",
		url: "/forgotPassword",
	},
	REGISTER_EXISTING_CUSTOMER: {
		id: 9,
		title: "Register",
		url: "/register",
	},
	// ORDER_DETAIL: {
	// 	id: 10,
	// 	title: "Order Detail",
	// },

	getAll: () => {
		return [
			// AccountLink.ACCOUNT_BALANCE,
			AccountLink.ORDER_HISTORY,
			// AccountLink.MAKE_PAYMENT,
			AccountLink.IMPORT_ORDER,
			AccountLink.USER_DETAILS,
			// AccountLink.TOBACCO_TRACK_AND_TRACE,
			// AccountLink.RETAIL_HUB,
			// AccountLink.ORDER_DETAIL,
		];
	},
};

export const OrderTypes = {
	CLICK_AND_COLLECT: {
		id: 1,
		name: "Click & Collect",
		description:
			"Our Click & Collect service allows you to shop the entire range online and collect it at a time that is convenient for you.",
		image: clickAndCollectIcon,
	},
	DELIVERED: {
		id: 2,
		name: "Delivered",
		description:
			"You can shop 24 hours a day, 7 days a week with this handy app. Simply choose this option if you require us to deliver your order.",
		image: deliveredIcon,
	},
};
