import React from "react";

import { AppConstants } from "../misc/Constants";
import singleSticker from "../assets/single.svg";
import AddToCart from "./addToCart";
import ProductImage from "./product-image";

const SingleImportedProduct = ({ product, barcode }) => {
	return (
		<tr>
			<td className="x">
				<img src="images/x3.svg" />
			</td>
			<td className="photo">
				<ProductImage
					supplierCode={product.supplier}
					productCode={product.code}
					largeImage={product.largeImage}
					missingImage={product.missingImage}
				/>
			</td>
			<td className="desc">
				<div className="desc1">{product.name}</div>
				<div className="desc2">
					{product.packsize == 1 ? (
						<img src={singleSticker} />
					) : (
						"Case of " + product.packsize
					)}{" "}
					{" x " + product.packsize_desc}
				</div>
				<div className="desc2">SKU: {product.code}</div>
			</td>
			<td className="barcode">Barcode: {barcode}</td>
			<td className="qtylab">Qty:</td>

			<td className="addcart-form">
				<form>
					<input
						className="addqty"
						type="text"
						defaultValue={product.qty}
						onChange={(e) => {
							product.qty = e.target.value;
						}}
					/>
					<input
						className="addcart"
						value="UPDATE"
						type="button"
						onClick={(e) => {
							product.qty = product.qty + 1;
						}}
					/>
					<div className="eor"></div>
				</form>
			</td>
			<td className="price">{AppConstants.POUND_SIGN + product.price}</td>
			<td className="eor"></td>
		</tr>
	);
};

export default SingleImportedProduct;