import React, { useState, useEffect } from "react";

import HorizontalProductsList from "./Horizontal-Products_List";

const HorizontalProductsGrid = ({ SpecialFilters }) => {
	return (
		<div className="container">
			{SpecialFilters.map((specialFilter, index) => {
				return (
					<HorizontalProductsList key={index} SpecialFilter={specialFilter} />
				);
			})}
		</div>
	);
};

export default HorizontalProductsGrid;

