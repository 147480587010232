import React from "react";

import shopAnythingImage from "../assets/shop-anytime.svg";
import appStoreImage from "../assets/download-app-store.svg";
import appImage from "../assets/app.png";
import { Utils } from "../Services/Utils";
import { AppConstants } from "../misc/Constants";

const AppLink = () => {
	return (
		<article className="col-sm-6">
			<div className="pad-lr">
				<div className="broapp">

					<div className="photo">
						<a href="#">
							<img src={appImage} />
						</a>
					</div>

					<div className="content">
						<div className="pad-b">
							<img src={shopAnythingImage} />
						</div>
						<div className="text pad-tb">
							With the United app, shop anywhere & be done in minutes
						</div>
						<div
							className="pad-t"
							onClick={() => {
								Utils.directToApp();
							}}
						>
							<img src={appStoreImage} />
						</div>
					</div>

					<div className="eor"></div>
				</div>
			</div>
		</article>
	);
};

export default AppLink;
