import { APIService } from "./APIService";

export const CartService = {
	getCartTotal: function (callback) {
		APIService.get(
			"get_cart_count.php",
			{ start: 1, filter: 2 },
			(jsonResponse, error) => {
				if (error != null) {
					callback(null, null, null, null, error);
				} else {
					const cart = jsonResponse.data.cart[0];
					const numberOfDifferentProducts = cart.no_of_rows;
					const subtotal = cart.subtotal;
					const vat = cart.vat;
					const numberOfCases = cart.qty;
					callback(
						numberOfDifferentProducts,
						subtotal,
						vat,
						numberOfCases,
						null
					);
				}
			}
		);
	},
	checkout: function (
		nonce,
		paymentMethodId,
		collectionDate,
		collectionSlot,
		collectionDay,
		callback
	) {
		APIService.post(
			"checkout.php",
			{
				paymentNonce: nonce,
				paymentMethodId: paymentMethodId,
				collectionDate: collectionDate ?? "",
				collectionTime: collectionSlot ?? "",
				collectionDay: collectionDay ?? "",
			},
			(jsonResponse, error) => {
				if (error != null) {
					callback(null, error);
				} else {
					callback(jsonResponse.data.msg, null);
				}
			}
		);
	},
	getOrders: function (callback) {
		APIService.get("get_orders_history.php", {}, (jsonResponse, error) => {
			if (error != null) {
				callback(null, error);
			} else {
				let orders = jsonResponse.data.orders ?? [];
				callback(orders, null);
			}
		});
	},
	getOrderDetails: function (orderNo, callback) {
		APIService.get(
			"get_orders_history.php",
			{ orderNo },
			(jsonResponse, error) => {
				if (error != null) {
					callback(null, error);
				} else {
					const orderItems = jsonResponse.data.orders;
					callback(orderItems, null);
				}
			}
		);
	},
	clearCart: function (callback) {
		APIService.get("clear_user_cart.php", {}, (jsonResponse, error) => {
			callback(error);
		});
	},
};
